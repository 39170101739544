.loginpage_main {
    width: 100%;
    height: auto;
    background-color: #F2E8E4;
    padding: 40px 0;
}

.loginpage_main .top_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.loginpage_main .login_logo {
    width: 450px;
    height: auto;
    overflow: hidden;
    background-color: rgb(168, 34, 34);
    border-radius: 4px;
}

.loginpage_main .login_logo img {
    width: 100%;
    height: 100%;
}


.loginpage_main .main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginpage_main .left {
    width: 100%;
    max-width: 40%;
    height: auto;
}

.loginpage_main .left img {
    width: 100%;
    height: 100%;
}


.loginpage_main .right {
    width: 100%;
    max-width: 50%;
    height: auto;
    margin: 0 auto;
}

.loginpage_main form,
.loginpage_main p,
.loginpage_main .social-sign-up {
    width: 100%;
    max-width: 70%;
    height: auto;
    font-family: 'Montserrat', sans-serif;
}


.loginpage_main form h4 {
    color: rgb(168, 34, 34);
    padding-bottom: 1em;
    font-family: 'Prata', serif;
    font-weight: 500;
    text-align: center;
}

.loginpage_main form .form-control {
    /* background-color: #e5e2e9; */
    opacity: 0.7;
    margin-bottom: 1.5em;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    box-shadow: none;
    outline: 0;
}

.loginpage_main form .submit-btn {
    background-color: rgb(168, 34, 34);
    color: #FFFFFF;
    width: 100%;
    box-shadow: none;
    text-decoration: none;
    outline: 0;
    font-family: 'Montserrat', sans-serif;
}

.loginpage_main form .submit-btn:hover {
    background-color: #F2E8E4;
    color: rgb(168, 34, 34);
    border: 1px solid rgb(168, 34, 34);
}


.loginpage_main form label {
    font-family: 'Montserrat', sans-serif;
}

.loginpage_main form p {
    color: #AAAAAA;
    font-family: 'Montserrat', sans-serif;
}


.loginpage_main .hr-lines {
    position: relative;
    max-width: 70%;
    margin: 50px 0;
    text-align: center;
}

.loginpage_main .hr-lines:before {
    content: " ";
    height: 2px;
    width: 150px;
    background: #AAAAAA;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
}

.loginpage_main .hr-lines:after {
    content: " ";
    height: 2px;
    width: 150px;
    background: #AAAAAA;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}


.loginpage_main .social-sign-up button {
    width: 100%;
    border: 1px solid #AAAAAA;
}

.loginpage_main .social-sign-up button:hover {
    background: rgb(168, 34, 34);
    color: #FFFFFF;
    border: 1px solid rgb(168, 34, 34);
}

.loginpage_main .social-sign-up .fa-google {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}

.loginpage_main .sign-in {
    margin: 1em 0;
    text-align: center;
}

.loginpage_main .sign-in p {
    font-family: 'Montserrat', sans-serif;
}

.loginpage_main .sign-in p a {
    color: rgb(168, 34, 34);
    font-weight: 600;
}


@media (max-width: 991px) {
    .loginpage_main .main {
        flex-wrap: wrap;
    }

    .loginpage_main .left {
        width: 100%;
        max-width: 100%;
        display: none;
    }

    .loginpage_main .right {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .loginpage_main form, .loginpage_main p, .loginpage_main .social-sign-up {
        max-width: 100%;
    }
    .loginpage_main .hr-lines { 
        max-width: 100%; 
    }
    .loginpage_main .hr-lines:before{
        width: 97px;
      }
      .loginpage_main .hr-lines:after{
        width: 97px;
      }
      .loginpage_main form .form-control{
        margin-bottom: 6px;
      }
      .loginpage_main form label{
        margin: 4px 0px;
      }
      .loginpage_main form h4{
        padding-bottom: 5px;
      }
      .loginpage_main .hr-lines{
        margin: 18px 0;
      }
      .loginpage_main .sign-in p{
        font-size: 13px;
      }
      .loginpage_main{
        padding: 15px 0;
      }
}
