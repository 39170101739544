body,
html {
  max-width: 100%;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans";
  color: #261e20;
  background-color: #fff;
}

ul li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  padding: 0;
  margin: 0;
}

a {
  color: #261e20;
}

a:hover {
  text-decoration: none;
  color: #261e20;
}

.btn,
.form-control {
  box-shadow: none;
  outline: none;
}

.scrollButtn {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.scrollButtn button {
  border: 0;
  background: transparent;

}

.scrollButtn button:hover {
  background: transparent;
}

.scrollButtn button svg {

  color: rgb(168, 34, 34);
  font-size: 43px;
}

.signup-pass {
  display: flex;
  /* background-color: #fff; */
}

.folkmbBtn {
  visibility: hidden;
  display: none !important;
}

.eyecon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  width: 2.5rem;
  margin-top: 1px;
  background-color: #ffffff;
  border-radius: 3px;
  border: none;
}

.nagacontainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4px 16px 4px 16px;
}

.accountOverlay {
  display: none;
}

/****header*****/
.toggolebar {
  display: none;
}

.Header {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  top: 0;
  z-index: 3;
  height: 54px;
}

.header.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.menu {
  display: flex;
}

.menuWrapper {
  width: 100%;
  max-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .AthenMenu {
  margin-left: 18%;
} */

.menu li {
  position: relative;
  padding: 0px 20px;
}

/* .menu li:nth-child(6) {
  border-left: 2px solid #858383;
  border-right: 2px solid #858383;
} */

.submenu_drop {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 11;
  background-color: rgb(168, 34, 34);
  color: #fff;
  width: 80%;
  height: auto;
  border-radius: 0px 0px 6px 6px;
  opacity: 0;
  visibility: hidden;
}

.menu li:hover .submenu_drop {
  opacity: 1;
  visibility: visible;
}

.menu li:hover a i.flip {
  transform: rotate(-180deg);
  transition: 0.6s;
}

.menu li a {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  /* color: rgba(245, 248, 251, 0.6); */
  color: #fff;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 30px;
  padding: 0;
}

/* 
.menu li a i {
  margin-left: 10px;
} */

.menu li a:focus {
  border-bottom: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);

  border-bottom: #fff;
  color: #fff;
}

.menu li a:hover {
  /* border-bottom: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0); */

  border-bottom: 1px solid #fff;
  color: #fff;

}

.menu li i {
  font-size: 16px;
}

.header-wrap {
  align-items: center;
  justify-content: space-between;
}

.logo {
  max-width: 256px;
  width: 100%;
  /* height: 44px; */
  height: auto;
}

.logo img {
  width: 100%;
  /* height: 100%; */
}

.header-wrapone {
  background-color: #fff;
}

.AthenMenu {
  display: flex;
  align-items: center;
}

.AthenMenu .menu li a {
  font-size: 11px;
}

.AthenMenu .menu li {
  padding: 10px 10px;
}

.redBtn {
  /* background: rgb(73, 88, 103); */
  background-color: #404040;
  border-radius: 2px;
  width: 150px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 13px;
  align-items: center;
  margin-left: auto;
  /* margin-right: -15px; */
  border-radius: 7px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Prata", serif;
}

.redBtn:hover {
  color: #fff;
}

.redBtn span img {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.hambarger {
  color: #e8e9ec;
  font-size: 30px;
  display: none;
}

/***header2****/
.mainBody {
  padding-top: 55px;
}

.header2 {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 95px;
}

.top-header {
  width: 100%;
  height: 100%;
  background-color: rgb(168, 34, 34);
  display: flex;
  align-items: center;
}

.top-header.black_header {
  width: 100%;
  height: 55px;
  background-color: #0a2a37;
}

.right .menu li a {
  margin-right: 30px;
  padding: 0;
}

.bottom-header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-top: 7px;
}

.bottom-header .right {
  width: 100%;
  margin: 7px 0;
}

.bottom-header .right form {
  display: flex;
  flex-direction: row;
  height: 35px;
  margin: auto 0 auto auto;
  width: 100%;
}

.bottom-header .right form input {
  padding: 0 15px 0 43px;
  width: 100%;
  border: 0;
  border-radius: 0.25rem 0 0 0.25rem;
  outline: 0;
  border-right: 2px solid #615d5d;
  margin-left: 20px;
}

.bottom-header .right form button {
  background-color: #ffffff;
  /* border-radius: 0 0.25rem 0.25rem 0; */
  color: #000000;
  font-size: 1rem;
  height: 100%;
  padding: 0 1rem;
  transition: background-color 0.2s ease-out;
  border: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.headTwoSeIcon {
  margin-left: 6px;
  color: #9f0213;
}

.headTwoForm {
  position: relative;
}

.searchGlass {
  position: absolute;
  top: 9px;
  left: 33px;
  font-size: 18px;
}

.header2 .bottom-header {
  background-color: #9f0213;
}

/**sidebar****/
.Sidebar {
  display: none;
}

.mainRemain {
  padding-top: 0;
}

/* footer update */
.nagaFootHead {
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  margin-bottom: 15px;
  color: #ffffff;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Prata", serif;
}

.digiText {
  font-size: 16px;
  font-weight: 500;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Prata", serif;
  letter-spacing: 1px;
}

.footLogoFig {
  width: 60px;
  height: 60px;
  margin: 0;
  margin: 0 auto;
}

.footLogoFig img {
  width: 100%;
  height: 100%;
}

.footLogoDiv {
  display: inline-block;
}

.footRightDiv {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  gap: 20px;
}

/* .footRightLi {
  margin-bottom: 8px;
} */

.footerUpdate {
  width: 100%;
  height: auto;
  background-color: rgb(53, 53, 53);
  padding: 50px 0 30px;
}

.footRightLiAn {
  color: #bfbcbc;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}

.footRightLiAn_updated {
  color: #bfbcbc;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}

.footRightLiAn_updated:hover {
  color: #ffffff;
}

.footRightLiAn:hover {
  color: #ffffff;
}

.footRightLiAn.big {
  font-size: 16px;
  text-decoration: underline;
}

.footRightLogoDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footSocialLogo {
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
}

.footSocialLogo:hover {
  color: #ffffff;
}

/* .socialFootLogoDiv {
  border-right: 3px solid #ffffff63;
} */

.footerRightLogText {
  color: #bfbcbc;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.bottomFooter p {
  line-height: 20px;
  font-style: italic;
}

.footerRightLogText:hover {
  color: #ffffff;
}

.footerRightTextDiv {
  width: 220px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.footCpyText {
  color: #ffffff94;
  text-align: right;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}



/***footer****/
.footer {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #261e20;
  color: #fff;
  padding: 28px 0;
  display: flex;
  align-items: center;
}

.footer .box h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #ffffff;
}

.footer .boxnew h2 {
  font-size: 23px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.footerLogoPart {
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer .boxnew h2 span {
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}

.footer .boxnew img {
  width: 43px;
  height: 43px;
}

.footer .box a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
  display: inline-block;
}

.footer .box a i {
  margin: 0 20px;
}

.footer_btndiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-footer {
  background-color: #000000;
  padding: 10px 0;
}

.bottom-footer p {
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.footer_btn {
  width: 180px;
  height: 40px;
  background-color: #9f0213;
  color: #e8e9ec;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  margin: 15px 0px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "\f105 " !important;
  font-family: FontAwesome !important;
  font-size: 30px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "\f104" !important;
  font-family: FontAwesome !important;
  font-size: 30px;
}

/****banner****/

.banner {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 700px;
}

.home_courtesy {
  position: absolute;
  right: -60px;
  transform: rotate(271deg);
  top: 50%;
  font-size: 13px;
}

.banner:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #ffffff63;
}

.bannerWrap {
  width: 100%;
  height: 575px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
  padding: 20px 0 40px;
}

.banner .topImg {
  display: flex;
  justify-content: space-between;
}

.redbg {
  color: #302d2d;
  background: #ffffff;
  padding: 0 12px;
  border: 0;
  height: 45px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.redbg i {
  color: #9f0213;
  font-size: 14px;
}

.myInpDiv {
  display: flex;
  align-items: center;
  margin-top: 45px;
}

.myInput {
  display: inline-block;
  width: 410px;
  position: relative;
  background-color: #fff;
}

.myInput i {
  font-size: 17px;
  color: #7f7b7b7d;
  /* font-weight: 400; */
}

.leftCnt {
  width: 60%;
  position: relative;
  z-index: 1;
  padding-top: 50px;
}

.leftCnt .pp {
  font-weight: 500;
  display: block;
  font-style: italic;
  font-family: "Prata", serif;
}

.rgtCnt {
  width: 43%;
  padding-left: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner:after {
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.banner h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  color: #000000;
}

.banner p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  color: #000000;
  margin-top: 30px;
}

.banner h1 {
  font-family: "Montserrat", sans-serif;
  align-items: center;
  /* letter-spacing: 0.11em; */
  color: #fff;
  font-size: 48px;
  padding: 0;
  line-height: 100px;
}

.banner h1 span {
  /* font-size: 100px; */
  font-weight: 500;
  line-height: 68px;
  font-family: "Prata", serif;
}

.myInput::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  width: 2px;
  height: 20px;
  background-color: #302d2d;
}

.myInput input {
  height: 45px;
  width: 99%;
  text-overflow: ellipsis;
  padding: 0 36px;
  background: #ffffff;
  /* border: 1.5px solid #000000; */
  /* box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.07); */
  color: #302d2d;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  border: 0;
  outline: none;
  font-family: "Montserrat", sans-serif;
}

.myInput input::placeholder {
  font-size: 14px;
  color: #302d2d;
  font-weight: 400;
}

.myInput i {
  position: absolute;
  top: 15px;
  left: 12px;
}

.bannerbox {
  width: 36%;
  height: auto;
  position: absolute;
  bottom: 35px;
  right: 0px;
  background-color: #fff;
  z-index: 1;
  padding: 15px 15px 25px;
}

.bannerbox_head {
  font-weight: 400;
  color: rgb(53, 53, 53);
  /* letter-spacing: 0px; */
  font-size: 24px;
  font-family: "Prata", serif;
}

.bannerbox p {
  color: rgb(53, 53, 53);
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}

.bannerbox a {
  font-family: "Prata", serif;
  color: #9f0213;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  /* font-family: 'Montserrat', sans-serif; */
}

.bannerbox a:hover {
  text-decoration: underline;
}

.banner_contribute {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: transparent;
  z-index: 1;
  padding: 15px 0;
}

.contribute_btn {
  display: flex;
  align-items: center;
  justify-content: end;
}

.contribute_btn .btn {
  width: 100%;
  max-width: 130px;
  height: 45px;
  text-decoration: none;
  border: none;
  outline: 0;
  padding: 5px 15px;
  background-color: rgb(168, 34, 34);
  color: rgb(255, 255, 255);
  font-family: "Prata", serif;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.contribute_btn .btn:hover {
  background-color: rgb(73, 88, 103);
  color: rgb(255, 255, 255);
}

.banner_contribute .text {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 4px;
}

.banner_contribute .text p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Prata", serif;
  font-style: italic;
  color: rgb(255, 255, 255);
  padding: 0;
  margin: 0 30px 0 0;
}

.collectionTop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 20px;
}

.ptrnFig {
  margin-bottom: 0;
  max-width: 300px;
  width: 50%;
  height: 40px;
  margin-left: 15px;
  display: none;
}

.ptrnFig img {
  width: 100%;
  height: 100%;
}

/* .conFlu {
  padding-left: 42px;
} */

.homeFea {
  font-style: italic;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  /* padding-top: 20px; */
  padding-bottom: 40px;
}

/*****blog****/
#blog {
  width: 100%;
  height: auto;
  padding: 30px 0px 70px;
}

.blog_body {
  display: grid;
  /* justify-content: center;
  align-items: flex-start; */
  padding: 0px 100px;
}

.headpagination {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.headpagination h2 {
  font-family: "Prata", serif;
  font-size: 48px;
  line-height: normal;
  text-align: left;
  color: rgb(26, 26, 26);
}

.historyleft {
  flex-basis: 20%;
  margin-right: 30px;
  background-color: white;
  border-radius: 10px;
}

.leftcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;
}

.hist_imgwrapper {
  width: 130px;
  height: 130px;
  padding-bottom: 15px;
}

.hist_imgwrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.history_lefthead {
  font-size: 18px;
  /* line-height: 22px; */
  color: #ab1a24;
  font-weight: 700;
}

.history_leftpara {
  font-size: 13px;
  line-height: 22px;
  color: #261e20;
  font-family: "Montserrat", sans-serif;
}

.grey-rule {
  width: 45%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  background-color: #c7c7c7;
}

.history_feature {
  width: 100%;
}

.hist_featureH {
  font-size: 15px;
  font-weight: 500;
  color: #261e20;
  font-family: "Montserrat", sans-serif;
}

.hist_featureP {
  display: block;
  font-size: 13px;
  color: #726e6e;
  padding: 2px 0px;
  font-family: "Montserrat", sans-serif;
}

.history_icon {
  width: 100%;
}

.history_icon i {
  color: #726e6e;
  font-size: 18px;
  letter-spacing: 10px;
}

.history_icon i:hover {
  color: black;
}

.webflow {
  width: 100%;
  color: #726e6e;
  font-size: 13px;
  padding: 5px 0px;
  font-family: "Montserrat", sans-serif;
}

.webflow_a {
  color: #726e6e;
  font-size: 13px;
}

.blog_right {
  flex-basis: 80%;
}

.right_listitem {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* background-color: white; */
  padding: 20px 15px;
  width: 100%;
  /* height: 200px; */
  border-radius: 10px;
}

.right_listimg {
  /* flex-basis: 30%; */
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  width: 277px;
}

.right_listimg img {
  width: 100%;
  height: 100%;
}

.right_listtext {
  flex-basis: 70%;
}

.history_head {
  font-family: "Prata";
  font-size: 24px;
  color: rgb(53, 53, 53);
  font-weight: 400;
}

.history_date {
  color: rgb(53, 53, 53);
  font-size: 16px;
  padding: 10px 0px;
  font-family: "Montserrat", sans-serif;
}

.history_para {
  color: rgb(53, 53, 53);
  font-size: 16px;
  line-height: 1.5;
  padding: 5px 0px;
  font-family: "Montserrat", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Contributing {
  margin-left: 15px;
  color: #261e20;
  font-family: "Montserrat", sans-serif;
}

.view_summary {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.Comment_btn {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  border: none;
  color: rgb(53, 53, 53);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  font-weight: 400;
  background-color: rgb(53, 53, 53);
  color: #fff;
  padding: 5px 15px;
  width: 100%;
  max-width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*****blog-details****/
#details {
  width: 100%;
  height: auto;
  padding: 20px 0px;
  /* background-color: #e8e9ec; */
}

/* .details_body {
  padding: 0px 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
} */

.detailsleft {
  flex-basis: 20%;
}

.detailsright {
  flex-basis: 80%;
  background-color: white;
}

.detailsright .details_rightcontent {
  /* max-width: 65%; */
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 0;
}

.details_img {
  width: 34%;
  /* height: 450px; */
  height: auto;
  /* margin-bottom: 25px; */
  margin: 0 auto;
}

.details_img img {
  width: 100%;
  height: 100%;
}

.details_text {
  padding: 15px 20px;
}

.details_head {
  font-family: "Prata", serif;
  font-size: 24px;
  color: rgb(53, 53, 53);
  font-weight: 500;
  line-height: 25px;
  padding-bottom: 10px;
  /* text-align: center; */
}

.details_date {
  color: #726e6e;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.details_para {
  font-size: 15px;
  color: #261e20;
  /* padding: 10px 0px; */
  font-family: "Montserrat", sans-serif;
}

.detailsgrey-rule {
  width: 150px;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  background-color: #c7c7c7;
}

.booklink {
  color: #ab1a24;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.booklink:hover {
  color: #ab1a24;
  text-decoration: underline;
}

.bookref_img {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 0px;
}

.bookref_imgwrap {
  width: 80px;
  height: 80px;
}

.bookref_imgwrap img {
  width: 100%;
  height: 100%;
}

.details_bodyhead {
  color: #261e20;
  padding: 15px 0px;
  font-weight: 500;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.details_bodypara {
  color: #726e6e;
  padding-bottom: 8px;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.details_bodylist {
  padding: 10px 25px;
}

.details_bodylist ul li {
  color: #261e20;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

/*****about****/
#about_intro {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

/* .aboutintro_body {
  padding: 0px 80px;
} */

.introhead {
  font-family: "Prata", serif;
  color: rgb(26, 26, 26);
  font-size: 64px;
  font-weight: 500;
  /* text-align: center; */
  margin-top: 40px;
}

.aboutintro_btm {
  padding-top: 30px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.aboutintro_left {
  flex-basis: 40%;
}

.introimg {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.introimg img {
  width: 100%;
  height: 100%;
}

.intro_text {
  flex-basis: 60%;
}

.introhead_btm {
  color: #ab1a24;
  font-size: 25px;
  font-weight: 600px;
  padding-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

.intropara_btm {
  font-family: "Montserrat";
  color: #261e20;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  /* max-width: 700px; */
  margin-bottom: 10px;
}

/* good points part */

#about_points {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

/* 
.about_pointsbody {
  padding: 0px 80px;
} */

.points {
  margin-bottom: 20px;
}

.abt_pointhead {
  color: rgb(10, 16, 21);
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: 500;
  font-family: "Prata", serif;
}

.about_pointpara {
  color: rgb(53, 53, 53);
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  max-width: 500px;
}

.about_bigimg {
  width: 100%;
  height: auto;
  padding: 40px 0;
}

.about_bigimg .img_wrap {
  width: 100%;
  height: 530px;
  overflow: hidden;
}

.about_bigimg .img_wrap img {
  width: 100%;
  height: 100%;
}

.about_bigimg .text {
  margin: 30px 0;
}

.about_bigimg .text p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  color: rgb(26, 26, 26);
  text-align: center;
  font-family: "Prata", serif;
  font-style: italic;
  padding: 0;
  margin: 0;
}

/* Overview part */

#overview {
  width: 100%;
  height: auto;
  padding: 30px 0px;
}

.overview_body {
  padding: 0px 80px;
}

.overview_btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overview_left {
  flex-basis: 40%;
  margin-left: 10px;
}

.overviewimg-wrap {
  width: 350px;
  height: 300px;
}

.overviewimg-wrap img {
  width: 100%;
  height: 100%;
}

.overview_right {
  flex-basis: 60%;
}

.overview_points {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.overview_number {
  flex-basis: 30%;
  color: #ab1a24;
  font-size: 24px;
  font-weight: 600;
  margin-right: 15px;
  font-family: "Montserrat", sans-serif;
}

.overview_number span {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.overview_text {
  flex-basis: 70%;
}

.OV_head {
  color: #ab1a24;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.ov_para {
  font-family: "Montserrat", sans-serif;
}

.overview_top {
  /* text-align: center; */
  padding-bottom: 20px;
}

.ov_tophead {
  color: #261e20;
  font-size: 35px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

/***feature***/
.feature {
  background-color: #fff;
  width: 100%;
  height: auto;
  padding: 10px 0 40px;
}

.feature.featuredsec {
  padding: 40px 0;
}

/* .feature .slick-slider .slick-track {
  width: 100% !important;
} */

h3.feco {
  font-family: "Prata", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  color: rgb(53, 53, 53);
  /* margin-bottom: 30px; */
  margin-bottom: 25px;
  line-height: 55px;
  font-weight: 500;
}

.feature .detailss {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: rgb(53, 53, 53);
  margin-bottom: 20px;
}

.feature .flx {
  display: flex;
  justify-content: space-between;
}

.feature .imgfc {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-width: 220px;
  max-width: 220px; */
  width: 100%;
  height: auto;
  margin: 0 10px 30px;
}

/* .feature.featuredsec .swiper-button-next:after,
.feature.featuredsec .swiper-button-prev:after {
  border-radius: 50%;
  box-shadow: 0 0 5px 0 #d3d3d3;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 5px;
  position: absolute;
  background: rgb(168, 34, 34);
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.feature.featuredsec .swiper-button-next:after,
.feature.featuredsec .swiper-button-prev:after {
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  /* color: rgb(168, 34, 34); */
  color: rgb(48, 63, 78);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature.featuredsec .swiper-button-prev,
.feature.featuredsec .swiper-button-next {
  top: 30%;
}

.feature.featuredsec .swiper-button-prev:hover:after,
.feature.featuredsec .swiper-button-next:hover:after {
  /* color: #fff;
  background-color: rgb(48, 63, 78); */
  color: #89848475 !important;
  background-color: rgb(136 139 143 / 25%) !important;
  transition: 0.6s;
}

.feature.featuredsec .imgfc {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0 5px 30px;
}

.imgFig {
  width: 100%;
  height: 214px;
  margin-bottom: 0;
}

.imgFig.imgONEffect {
  position: relative;

}

/* .imgONEffect:hover{
  transition: all 0.5s ease;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.32);
  margin-left: -15px;
  width: calc(100% + 30px);
}
.imgONEffect:hover .imgOnHover{
  opacity: 1;
} */
.imgOnHover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background 0.7s ease;
  opacity: 0;
  align-items: end;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
}

div.floatright {
  margin: 0 0px 30px 44px;
  position: relative;
  clear: right;
  float: right;
}

.floatright {
  float: right;
  clear: right;
  position: relative;
  margin: 0 0px 30px 44px;
}

.imgOnHover p {
  position: relative;
  z-index: 9;
  font-size: 12px;
  color: #000;
  font-weight: bold;
  -webkit-line-clamp: 2;
  height: 73px;
  overflow: hidden;
  width: 70%;
  line-height: 18px;
}

.featuredsec .imgFig {
  width: 100%;
  height: 200px;
  margin: 0;
}

.documentfeature .imgFig {
  width: 246px;
}

.imgFig img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature .box {
  width: 100%;
  height: auto;
  padding: 0;
}

/* .feature .imgfc:hover .box {
  bottom: 50px;
  transition: .5s all ease-in-out;
  cursor: pointer;
} */

.feature h5.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 18px;
  margin-bottom: 10px;
  font-family: "Prata", serif;
  color: rgb(53, 53, 53);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 62px;
}

.feature p.para {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 19px; */
  color: #000000;
  text-align: justify;
}

.feature p.para2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 19px; */
  color: #737171;
  text-align: justify;
}

.feature .slick-prev {
  top: 40%;
  left: -37px;
  z-index: 1;
}

.feature .slick-next {
  top: 40%;
  right: -31px;
  z-index: 1;
}

/* .feature .slick-prev:before,
.feature .slick-next:before {
  line-height: 1;
  opacity: 0.75;
  background: rgb(168, 34, 34);
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 700;
  padding: 5px 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.feature .slick-prev:before,
.feature .slick-next:before {
  color: rgb(168, 34, 34);
  font-size: 16px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature .slick-prev:hover:before,
.feature .slick-next:hover:before {
  /* color: #fff !important;
  background-color: rgb(48, 63, 78) !important; */
  transition: 0.6s;
}

.feature .slick-prev:before {
  content: "\f104";
  font-family: FontAwesome;
}

.feature .slick-next:before {
  content: "\f105";
  font-family: FontAwesome;
}

/* .collection_top,
.collection_btm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
} */

/* .collection_list {
  overflow-x: auto;
  padding-bottom: 15px;
} */

/* .collection_list::-webkit-scrollbar {
  display: none;
} */

.collection_list .slick-prev:before,
.collection_list .slick-next:before {
  /* color: rgb(168, 34, 34); */
  color: rgb(48, 63, 78) !important;
  font-size: 16px;
  font-weight: 700;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection_list .slick-prev:hover:before,
.collection_list .slick-next:hover:before {
  /* color: #fff !important;
  background-color: rgb(48, 63, 78) !important; */
  color: #89848475 !important;
  background-color: rgb(136 139 143 / 25%) !important;
  transition: 0.6s;
}

.collection_list .slick-prev:before {
  content: "\f104";
  font-family: FontAwesome;
}

.collection_list .slick-next:before {
  content: "\f105";
  font-family: FontAwesome;
}


.seemore_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px 15px;
}

.seemore_div a {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(168, 34, 34);
  color: #fff;
  padding: 5px 15px;
  width: 100%;
  max-width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: 0;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 4px;
}

.seemore_div a:hover {
  color: #fff;
  background-color: rgb(48, 63, 78);
  transition: 0.6s;
}

@media (max-width: 1249px) {
  .feature .slick-prev {
    top: 40%;
    left: 0px;
    z-index: 1;
  }

  .feature .slick-next {
    top: 40%;
    right: 0px;
    z-index: 1;
  }
}

@media (max-width: 480px) {
  .feature .slick-prev {
    top: 40%;
    left: -10px;
    z-index: 1;
  }
}

/*******contactus_sec********/

.contactus_sec {
  width: 100%;
  height: auto;
  padding: 25px 0 50px;
  background-color: rgb(249, 246, 240);
}

.contactus_sec .text_oo {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #000;
  padding: 0;
}

.contactus_sec .rightimg {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 0;
}

.contactus_sec .rightimg img {
  width: 100%;
  height: 100%;
}

/****about-info****/

.img-box {
  width: 100%;
  max-width: 567px;
  height: 100%;
  margin: 0 auto;
}

.img-box img {
  width: 100%;
  height: 100%;
}

.chkCol {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.ptrnFig2 {
  margin-bottom: 0;
  max-width: 362px;
  width: 100%;
  max-height: 55px;
  height: 100%;
  margin-right: 15px;
}

.ptrnFig2 img {
  width: 100%;
  height: 100%;
}

.readMoreLink {
  font-size: 22px;
  font-weight: 700;
  color: #9f0213;
  font-family: "Montserrat", sans-serif;
}

.readMoreLink:hover {
  color: #9f0213;
}

p.fact {
  /*     font-family: 'Montserrat', sans-serif; */
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: rgb(77 75 75);
  margin-left: 2px;
  margin-bottom: 20px;
  text-align: justify;
}

.slick-track {
  margin-left: 0 !important;
}

h3.sia {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height */
  /* text-align: center; */
  color: #000000;
}

span.nga {
  color: #9f0213;
}

.sian {
  width: 100%;
  height: auto;
  padding: 50px 0;
}

.sian .img-box {
  width: 100%;
  height: auto;
}

.readBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

/****partner****/
.contriBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
}

.contriBtn {
  border: 1px solid #9f0213;
  background-color: #9f0213;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 20px;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
}

.cmeJoinText {
  /* color: #5a5858; */
  color: rgb(53, 53, 53);
  text-align: center;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  font-family: "Montserrat", sans-serif;
  max-width: 900px;
  margin: 0 auto 30px;
}

.partner .animation {
  margin: auto;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-top: 60px;
}

.btn-12 span {
  color: #fff;
  background: #9f0213;
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: "Montserrat", sans-serif;
}

.btn-12 span:nth-child(1) {
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.btn-12:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}

.btn-16:hover {
  color: #000;
}

.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.btn-16:active {
  top: 2px;
}

.btn-12 {
  position: relative;
  right: 20px;
  bottom: 20px;
  border: none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}

.partner {
  width: 100%;
  height: auto;
  padding: 40px 0;
}

.main-flx {
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow-x: auto;
}

.main-flx::-webkit-scrollbar {
  display: none;
}

.sub-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* margin-bottom: 20px; */
  /* margin-right: 20px; */
  width: 120px;
}

/* img.partner-logo {
  width: 20%;
  margin-right: 10px;
} */

.partnerLogoFig {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0;
}

.partnerLogoFig img {
  width: 100%;
  height: 100%;
}

h5.partner-name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  /* line-height: 122.1%; */
  color: #000000;
  margin-top: 12px;
}

.butn {
  margin: auto;
  display: flex;
  justify-content: center;
  background: #9f0213;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 18px 36px;
  margin-top: 60px;
  border: 0;
  align-items: center;
}

a.see {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 133.7%;
  color: #f8f8f8;
  text-decoration: none;
  align-items: center;
}

i.see-icon {
  background: #f5c9c9;
  font-size: 10px;
}

a.see:hover {
  text-decoration: none;
  color: #f5c9c9;
  transition: 0.5s all ease;
}

.butn:hover {
  transition: 0.5s all ease;
}

/* get in touch section */
.getInForm {
  max-width: 400px;
  width: 100%;
}

.getInFrmDiv {
  width: 100%;
  display: flex;
}

.firstNameInp {
  margin-right: 5px;
}

.nameInps {
  width: 49.5%;
  padding: 5px 10px;
  font-size: 18px;
  outline: none;
  margin-bottom: 10px;
  border: 2px solid #261e20;
  border-radius: 3px;
  font-family: "Montserrat", sans-serif;
}

.getFrmInps {
  width: 100%;
  padding: 5px 10px;
  font-size: 18px;
  outline: none;
  margin-bottom: 10px;
  border: 2px solid #261e20;
  border-radius: 3px;
  font-family: "Montserrat", sans-serif;
}

.getSubBtn {
  font-size: 22px;
  padding: 10px 50px;
  background-color: #9f0213;
  border: 1px solid #9f0213;
  color: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
}

.getInColClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.getInTextP {
  font-size: 20px;
  margin-bottom: 5px;
  color: #666060;
  font-family: "Montserrat", sans-serif;
}

.textAreaInp {
  resize: none;
}

.getInTouch {
  margin: 50px 0;
}

.partner .slick-prev {
  left: 0;
  z-index: 2;
}

.nagasidevarBG {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
}

.partner .slick-next {
  right: 5px;
}

/* start collection */

.collection .colle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 0px;
}

.collection .para {
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 35px;
  color: #000000;
  text-align: center;
  margin-top: 10px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.collec-img {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.collecFig {
  margin: 0;
  width: 100%;
  height: 300px;
}

.collecFig img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* .collec-img img {
  width: 100%;
  height: 100%
} */

.collecTxtDiv {
  width: 100%;
  height: 99%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000060;
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
  padding-left: 22px;
}

.collection .collec-txt {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  /* text-align: center; */
  /* position: absolute;
  bottom: 20px;
  left: 20px; */
}

/****breadcrumb-header****/
.breadcrumbs-header {
  background-color: transparent;
  padding: 10px 0;
}

.breadcrumbs-header nav a {
  color: #9f0213;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.breadcrumbs-header nav a i {
  margin: 5px;
}

/***tabspart***/
.tabsPart h3 {
  color: #4f050a;
  font-size: 25px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0px 0px 5px;
  font-family: "Prata", serif;
}

.tabsPart .react-tabs__tab img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.rts___tab___selected {
  background: #00000000 !important;
  display: inline-block !important;
}

.rts___tab {
  border: 0 !important;
  border-radius: 30px !important;
  margin: 5px 2px !important;
  padding: 6px 9px !important;
  box-shadow: none !important;
}

.react-tabs__tab {

  /* background-color: #E8E9EC !important; */
  border: 0 !important;
  border-radius: 30px !important;
  margin: 5px 2px;
  padding: 6px 9px !important;
}

/* .react-tabs__tab:focus {
  border: 1px solid #4f050a !important;
} */

.react-tabs__tab a {
  white-space: nowrap;
  color: #261e2099;
  font-weight: 500;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

.react-tabs__tab--selected {
  background: #00000000 !important;
  display: inline-block !important;
}

.rts___tab a {
  color: #261e2099;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}

.rts___tab___selected a {
  color: #4f050a;
  font-weight: bolder;
}

.display___md___none {
  display: flex !important;
}

.react-tabs__tab--selected a {
  color: #4f050a;
  font-weight: bolder;
}

.react-tabs__tab-list {
  border-bottom: 0 !important;
  padding-bottom: 5px !important;
}

.rts___tabs {
  display: flex;
  justify-content: flex-end;
  overflow-y: hidden;
  width: 100%;
}

.tabListTag {
  /* border-bottom: 1px solid #c0b7b7; */
  display: flex;
  justify-content: flex-end;
  overflow-x: auto;
  overflow-y: hidden;
}

.tabListTag::-webkit-scrollbar {
  display: none;
}

.tabListTag.searchCollectionTab {
  justify-content: center;
}

/* history & heritage part */

#history {
  width: 100%;
  height: auto;
  /* background-color: #E8E9EC; */
  color: #261e20;
}

.history_body {
  /* display: flex;
  justify-content: space-evenly;
  align-items: flex-start; */
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.history_left {
  text-align: center;
  margin-right: 10px;
}

.left-content {
  background-color: #f8f1e4;
  width: 100%;
}

.left-content p {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: rgb(50, 50, 50);
}

.left-content h5 {
  font-weight: 500;
  font-size: 18px;
  font-family: "Prata";
}

.left_imgwrapper {
  border-bottom: 4px solid #9f0213;
  position: relative;
}

.left_imgwrapper img {
  width: 100%;
  height: 210px;
}

.histTitle {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #ffffff;
}

.photoFig {
  /* width: 100%; */
  height: 210px;
  margin: 0;
  width: 220px;
  position: relative;
  cursor: pointer;
}

.photoFig img {
  width: 100%;
  height: 100%;
}

/* .photoFig .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000002f;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
}

.photoFig:hover .overlay {
  opacity: 1;
} */

.photoFigDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.photoFigDiv>div {
  width: 220px;
}

.photoFigAfter {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
}

.list-item {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* margin: 10px 0px; */
  padding: 15px;
  border-bottom: 1px solid rgb(209, 204, 204);
}

.last-item:last-child {
  border-bottom: 0 !important;
}

.listimg_wrapper {
  border: 1px solid rgb(209, 204, 204);
  width: 100%;
  max-width: 186px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.listimg_wrapper.Documents {

  max-width: 152px;
  height: 188px;

}

.listimg_wrapper.video_list_wrapper {
  border: 1px solid rgb(209, 204, 204);
  width: 100%;
  max-width: 235px;
  height: 158px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.listimg_wrapper img {
  width: 100%;
  height: 100%;
}

.listimg_wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.listimg_wrapper:focus {
  border: 4px solid #f7a80e;
}

.list_text {
  width: 100%;
  margin-left: 30px;
  line-height: 15px;
}

/* .citation {
  padding-left: 20px;
  font-family: 'Montserrat', sans-serif;
} */

.list_text h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  color: rgb(168, 34, 34);
}

.list_text p {
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 10px;
  line-height: 22px;
  color: rgb(96 95 95);
  font-family: "Montserrat", sans-serif;
}

.list_text p.italic {
  font-style: italic;
}

.list_text p a {
  color: rgb(96 95 95);
  font-family: "Montserrat", sans-serif;
}

/* Summary part */
#summary {
  width: 100%;
  height: auto;
  /* background-color: #E8E9EC; */
  background-color: white;
  color: #261e20;
  padding: 20px 0px;
}

.summary_body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15px;
}

.summary_left {
  width: 30%;
  margin-right: 10px;
  padding: 20px 15px;
}

.summary_lefttop h3 {
  text-align: center;
  font-size: 20px;
  padding-bottom: 15px;
  font-weight: 700;
  color: #261e20;
}

.summaryimg-wrapper {
  width: 150px;
  height: 200px;
  margin: 0px auto;
}

.summaryimg-wrapper img {
  width: 100%;
  height: 100%;
}

.summary_lefttop a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.summary_lefttop a button {
  width: 125px;
  height: 28px;
  border: none;
  background-color: #9f0213;
  color: #e8e9ec;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}

.summary_lefttop a button i {
  padding-left: 10px;
}

.summary_lefttop a button:hover {
  background-color: #261e20;
}

.summary_right {
  flex-basis: 70%;
  padding: 20px 40px;
  text-align: justify;
  /* background-color: #E8E9EC; */
}

.summary_right h3 {
  font-size: 48px;
  padding-bottom: 10px;
  color: #261e20;
  /* border-bottom: 1px solid #00000036; */
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Prata", serif;
}

.summary_right p {
  font-weight: 400;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: rgb(50, 50, 50);
}

.link {
  padding: 10px 0px;
  /* text-align: center; */
}

.link a {
  color: #9f0213;
  font-size: 13px;
}

.link:hover {
  text-decoration: underline;
  color: #9f0213;
}

.summary_leftbtm {
  padding: 5px 15px 10px 15px;
  /* background-color: #E8E9EC; */
}

.bottom_list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 12px 0px;
}

.left_heading {
  width: 100%;
  height: auto;
}

.url {
  word-break: break-word;
}

.url i {
  color: #9f0213;
}

.right_heading {
  font-size: 15px;
  flex-basis: 65%;
  margin-left: 10px;
}

.right_heading a {
  font-weight: bolder;
}

.right_heading ul {
  list-style-type: none;
}

.right_heading i {
  padding-left: 5px;
  font-size: 12px;
}

.summary_leftbtm a {
  font-weight: 500;
}

.summary_leftbtm a:hover {
  text-decoration: underline;
}

.summary_leftbtm p {
  font-size: 14px;
  color: rgb(50, 50, 50);
  margin-bottom: 20px;
  /* text-align: justify; */
  font-family: "Montserrat", sans-serif;
  display: flex;
}

.summary_leftbtm p span {
  font-size: 13px;
  margin-left: 5px;
  width: 100%;
  display: block;
  word-break: break-all;
}

.right_img {
  display: flex;
  justify-content: center;
  /* align-items: baseline; */
  margin-top: 20px;
}

.rightimg-wrapper {
  width: 50px;
  height: 60px;
}

.rightimg-wrapper img {
  width: 100%;
  height: 100%;
}

.rightimg-wrapper:hover {
  border: 3px solid #9f0213;
}

/****terms & condition****/
.terms-title h1 {
  font-size: 30px;
  margin-bottom: 31px;
  text-align: center;
  color: #9f0213;
  font-family: "Montserrat", sans-serif;
}

.terms-content ul.terms-policy li h2 {
  font-size: 16px;
  color: #261e20;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.terms-content ul.terms-policy li p.trems-desc {
  padding-left: 20px;
  display: block;
  margin-top: 6px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.terms-content ul.terms-policy li {
  margin-top: 16px;
}

/***privacy-policy***/
.privacy-content ul li {
  list-style-type: unset;
}

.privacy-content ul.privacy-policy li {
  margin-top: 5px;
  font-size: 14px;
}

.priVacyP {
  font-family: "Montserrat", sans-serif;
}

/****faq***/
.faq-title p {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.faq-box ul.faq-ul li.faq-li h3 {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.faq-box ul.faq-ul li.faq-li h3 span {
  color: #9f0213;
}

.faq-box ul.faq-ul li.faq-li span.faq-desc {
  padding-left: 20px;
  margin-top: 6px;
  line-height: 25px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
}

.btn-style1:hover {
  color: #000;
  background-color: transparent;
}

.faq-start a.collapse-title {
  background-color: #9f0213;
  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  display: block;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.faq-start span {
  background-color: #fff;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
  font-family: "Montserrat", sans-serif;
}

.faq-start {
  margin-bottom: 30px;
}

.faq-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
}

.faq-box ul.faq-ul {
  width: calc(50% - 30px);
  margin-left: 30px;
  margin-top: -16px;
}

.faq-box a.btn-style1 {
  margin: 0 auto;
  margin-top: 22px;
  font-family: "Montserrat", sans-serif;
}

.faq-title {
  margin-bottom: 37px;
}

.btn-style1 {
  color: #fff;
  font-size: 14px;
  padding: 10px 30px;
  background-color: #9f0213;
  font-weight: 600;
  border: 2px solid #9f0213;
  border-radius: 7px;
}

.faq-box ul.faq-ul li.faq-li {
  margin-top: 16px;
}

/* Contact part */
#contact {
  width: 100%;
  height: auto;
  /* background-color: #E8E9EC; */
  background-color: white;
  color: #261e20;
  padding: 40px 0px;
}

/* .contact_body {
  padding: 0px 50px;
} */

.contact_top {
  padding: 20px 30px 50px;
  text-align: center;
}

.contact_top h2 {
  padding-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

.contact_top h5 {
  font-size: 48px;
  color: #261e20;
  font-weight: 400;
  padding: 10px 0px 30px;
  font-family: "Prata", serif;
}

.contact_top p {
  color: #56565e;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.details {
  padding: 20px 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.details_list {
  margin: 0 10px 20px;
}

.details_list p {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.details_list a i {
  font-size: 28px;
  color: #9f0213;
  margin-bottom: 15px;
}

.contact_btm {
  padding: 20px 30px;
  /* background-color: #E8E9EC; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact_btm .left_arae {
  width: 90%;
  height: 100%;
  overflow: hidden;
}

.contact_btm .left_arae img {
  width: 100%;
  height: 100%;
}

.contact_btm .right_area {
  width: 100%;
  height: auto;
}

.contact_btm .text p {
  font-family: "Montserrat", sans-serif;
  color: rgb(26, 26, 26);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 25px;
  padding: 0;
}

.contact_btm .form-group {
  margin-bottom: 20px;
}

.contact_btm .form-group label {
  font-size: 16px;
  color: rgb(26, 26, 26);
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.contact_btm .form-group input {
  width: 100%;
  height: 48px;
  padding: 16px;
  color: rgb(136, 136, 136);
  background-color: rgb(241, 241, 241);
  /* border-color: rgb(0, 0, 0); */
  border-radius: 0;
  border: none;
  box-shadow: none;
  text-decoration: none;
  outline: 0;
}

.contact_btm .form-group input::placeholder {
  color: #9e9d9d93;
}

.contact_btm .form-group textarea {
  width: 100%;
  padding: 16px;
  color: rgb(136, 136, 136);
  background-color: rgb(241, 241, 241);
  /* border-color: rgb(0, 0, 0); */
  border-radius: 0;
  border: none;
  box-shadow: none;
  text-decoration: none;
  outline: 0;
}

.contact_btm .form-group textarea::placeholder {
  color: #9e9d9d93;
}

.contactsubmit_btn .btn {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(168, 34, 34);
  color: #fff;
  padding: 5px 15px;
  width: 100%;
  max-width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: 0;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  margin-top: 30px;
  border-radius: 7px;
}

.contactsubmit_btn .btn:hover {
  color: #fff;
  background-color: rgb(48, 63, 78);
  transition: 0.6s;
}

.contact_form {
  flex-basis: 60%;
  margin-right: 20px;
}

.contact_form form {
  background-color: white;
  border-radius: 15px;
  padding: 20px 20px;
}

.form_text {
  padding-bottom: 20px;
}

.form_text h2 {
  line-height: 50px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.form_text p {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

.social_media h2 {
  line-height: 50px;
}

.social_media p {
  font-size: 15px;
}

/* .contact_body label {
  display: block;
  font-size: 15px;
  font-weight: 600;
  margin: 8px 0px;
  font-family: 'Montserrat', sans-serif;
} */

.contact_body input {
  background-color: #e8e9ec;
  border: 1px solid grey;
  border-radius: 8px;
  width: 100%;
  height: 35px;
  padding: 10px 5px;
  outline: none;
  font-family: "Montserrat", sans-serif;
}

#msg {
  background-color: #e8e9ec;
  border: 1px solid grey;
  border-radius: 8px;
  resize: none;
  color: black;
}

.contact_body input[type="email"],
input[type="message"] {
  display: block;
}

.contact_body input::placeholder {
  padding: 10px 5px;
  font-size: 15px;
}

/* .contact_body button {
  display: block;
  width: 100%;
  height: 45px;
  margin: 20px 0px;
  border: none;
  border-radius: 8px;
  background-color: #9F0213;
  color: white;
  font-family: 'Montserrat', sans-serif;
} */

.contact_body button:hover {
  background-color: #261e20;
  color: #e8e9ec;
}

.social_media {
  text-align: center;
  flex-basis: 40%;
}

.social_media h2 {
  padding: 15px 0px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.social_media p {
  padding-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

.social_media a i {
  color: #9f0213;
  font-size: 25px;
  letter-spacing: 20px;
}

.social_media a i:hover {
  color: #261e20;
}

.map {
  padding-top: 20px;
  width: 100%;
}

.map iframe {
  width: 100%;
}

/***pagination***/
.paginationwrapper {
  margin: 0 auto;
  display: table;
  padding: 20px 0;
}

/* page */

.page {
  background: transparent;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #9f0213;
  border: 1px solid #9f0213;
}

.pagestyle {
  background: #9f0213;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.paginationwrapper ul li a {
  background: #9f0213;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.paginationwrapper ul li {
  margin-right: 15px;
}

.paginationwrapper .page-link:hover {
  color: #9f0213;
}

.page-link:focus {
  box-shadow: none;
}

/****cookie****/
.cookie-content .wrap {
  margin-top: 30px;
}

.cookie-content .wrap h3 {
  font-size: 25px;
  margin-bottom: 20px;
}

/****news & updates****/
#news {
  width: 100%;
  height: auto;
  padding: 30px 0px;
  /* background-color: #E8E9EC; */
}

.news_top {
  text-align: center;
  padding: 40px 0px;
}

.tophead {
  font-size: 35px;
  color: #9f0213;
}

.news_btm {
  padding: 0px 30px;
}

.news_list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0px;
}

.news_text {
  flex-basis: 80%;
  padding-left: 20px;
}

.newshead {
  color: #9f0213;
  padding-bottom: 10px;
  font-size: 18px;
}

.newspara {
  color: #261e20;
  font-size: 15px;
}

.news_img {
  flex-basis: 20%;
  width: 220px;
  height: 120px;
}

.news_img img {
  width: 100%;
  height: 100%;
}

.date {
  color: #56585c;
  font-size: 12px;
  margin-top: 25px;
}

.photoSubFig {
  width: 300px;
  height: 195px;
  margin-bottom: 5px;
}

.photoSubFig img {
  width: 100%;
  height: 100%;
}

.photoSubFigDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .photoSubDivsRow{
  border: 2px solid red;
} */

.clouSmallHead {
  font-size: 18px;
  color: #555050;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}

.clouSmallDate {
  font-size: 13px;
  color: #868282;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}

.clouSmallText {
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.cloudsRowSec {
  margin-top: 50px;
  margin-bottom: 30px;
}

.photoSubAfterText {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.learnMFig {
  max-width: 100px;
  width: 100%;
  max-height: 90px;
  height: 100%;
  margin: 0;
}

.learnMFig img {
  width: 100%;
  height: 100%;
}

.smallLogos {
  width: 32px;
  height: 32px;
  margin: 0;
  margin-right: 5px;
}

.smallLogos img {
  width: 100%;
  height: 100%;
}

.smallLogsDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.smallLogsMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smallDocText {
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.booksAfterFig {
  width: 100px;
  height: auto;
  margin-bottom: 0;
}

.booksAfterFig img {
  width: 100%;
  height: 100%;
}

.smallDocAn {
  font-size: 10px;
  font-weight: 700;
}

.smallDocLi {
  line-height: 15px;
}

.allPhotoRow {
  margin-top: 30px;
}

.allPHead {
  font-family: "Montserrat", sans-serif;
}

/******thematic_page*****/

.thematic_page {
  width: 100%;
  height: auto;
}

.bradecum_head {
  width: 100%;
  height: auto;
  background-color: rgb(249, 246, 240);
  padding: 40px 0 40px;
}

.bradecum_head .head {
  text-align: center;
}

.bradecum_head .head h4 {
  font-family: "Prata", serif;
  font-size: 48px;
  line-height: normal;
  font-weight: 500;
  color: rgb(50, 50, 50);
  padding: 0;
  margin: 0;
}

.bradecum_head .text p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgb(50, 50, 50);
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  padding: 0;
  margin: 0;
  max-width: 600px;
}

.battle_Kohima {
  width: 100%;
  height: auto;
  padding: 50px 0 80px;
}

.battle_Kohima .box_wraper {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
}

.battle_Kohima .box_book {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 0 6px 35px;
  cursor: pointer;
}

.battle_Kohima .box_book .img_area {
  width: 100%;
  /* height: 190px; */
  height: 260px;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
}

.battle_Kohima .box_book .img_area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.battle_Kohima .box_book .img_area .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000002f;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
}

.battle_Kohima .box_book .img_area:hover .overlay {
  opacity: 1;
}


.battle_Kohima .box_book .name h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: normal;
  color: rgb(50, 50, 50);
  font-weight: 500;
  margin-bottom: 15px;
  text-decoration: underline;
  height: auto;
  overflow: hidden;
}

.myPhotos {
  padding: 20px 20px 0px !important;
}

.feature.myPhotos .slick-prev {
  top: 40%;
  left: -33px;
}

.feature.thematicvideo_sec .slick-next {

  right: -29px;

}

.feature.myPhotos .slick-next {
  top: 40%;
  right: -24px;
}

.swiper-slide a {
  width: 100%;
  display: block;
}

.slick-next {
  background-color: transparent !important;
}

.slick-next:before {
  /* color: rgb(168, 34, 34) !important; */
  /* background-color: rgb(48, 63, 78) !important; */
  content: "\f105 " !important;
  font-family: FontAwesome !important;
  font-size: 30px !important;
  color: rgb(48, 63, 78) !important;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.slick-prev:before {
  /* color: rgb(168, 34, 34) !important; */
  font-size: 30px !important;
  content: "\f104 " !important;
  font-family: FontAwesome !important;
  font-size: 30px !important;
  color: rgb(48, 63, 78) !important;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  /* color: #fff !important;
  background-color: rgb(48 63 78 / 38%) !important; */
  color: #89848475 !important;
  background-color: rgb(136 139 143 / 25%) !important;
  transition: 0.6s;

}

.battle_Kohima .box_book .text h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: normal;
  color: rgb(50, 50, 50);
  font-weight: 500;
  margin-bottom: 10px;
}

.battle_Kohima .box_book .text p {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: normal;
  color: rgb(50, 50, 50);
  font-weight: 500;
  margin-bottom: 10px;
  height: 75px;
  overflow: hidden;
}

.battle_Kohima .box_book .descript p {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: normal;
  color: rgb(50, 50, 50);
  font-weight: 500;
  margin-bottom: 10px;
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-decoration: underline; */
}

/****thematicdetails_page******/

.thematicdetails_page {
  width: 100%;
  height: auto;
}

.thematicdetails_page .headofthe_page {
  width: 100%;
  height: auto;
  padding: 40px 0 25px;
}

.thematicdetails_page .headofthe_page h2 {
  font-size: 48px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-align: center;
  font-family: "Prata", serif;
}

.sammry_sec {
  width: 100%;
  height: auto;
  padding: 40px 0 60px;
}

.sammry_sec .left_aera {
  width: 100%;
  height: 494px;
  overflow: hidden;
}

.sammry_sec .left_aera img {
  object-fit: cover;
}

.sammry_sec .left_aera img {
  width: 100%;
  height: 100%;
}

.sammry_sec .right_area h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  /* margin-bottom: 20px; */
  color: rgb(53, 53, 53);
}

.sammry_sec .right_area p {
  font-size: 16px;
  line-height: 1.5;
  padding: 0;
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-weight: 400;
}

.sammry_sec .right_area p.italic {
  font-style: italic;
}

.sammry_sec .right_area p a {
  font-size: 16px;
  color: #1155cc;
  font-weight: 600;
  text-decoration: underline;
  font-style: italic;
  text-decoration-skip-ink: none;
}

.sammry_sec .right_area p a i {
  font-size: 14px;
  /* color: rgb(80, 79, 79); */
  font-weight: 400;
  color: #1155cc;
}

.thematicvideo_sec {
  width: 100%;
  height: auto;
  padding: 10px 0 60px;
}

.thematicvideo_sec .head_line {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.thematicvideo_sec .head_line h2 {
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: rgb(53, 53, 53);
  font-weight: 600;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.thematicvideo_sec .allcard_wrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.thematicvideo_sec .cardbox {
  width: 100%;
  max-width: 375px;
  height: auto;
  overflow: hidden;
  margin: 0 6px 30px;
}

.thematicvideo_sec .cardbox .video_ {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.thematicvideo_sec .cardbox .video_ video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thematicvideo_sec .cardbox .detailsss {
  width: 100%;
  height: auto;
  padding: 15px 0;
}

.thematicvideo_sec .cardbox .detailsss p {
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 0 0 15px;
}

.thematicvideo_sec .cardbox .detailsss .maintext {
  height: auto;
}

.thematicvideo_sec .cardbox .detailsss .description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  font-size: 12px;
  line-height: 16px;
}

.thematicvideo_sec .cardbox .detailsss .author {
  font-size: 12px;
}

/* Books section */

#books {
  width: 100%;
  height: auto;
  font-family: "Montserrat", sans-serif;
  padding: 20px 0 60px;
}

.bookshead {
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: rgb(53, 53, 53);
  font-weight: 600;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.booksflex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.booksdiv {
  width: 100%;
  /* max-width: 185px; */
  margin: 0 5px 30px;
}

.booksdivs {
  width: 100%;
  /* max-width: 185px; */
  margin: 0 0px;
}

.bookimgwrap {
  width: 140px;
  height: 180px;
  margin: 0 auto;
}

.bookimgwrap img {
  width: 100%;
  height: 100%;
}

/* dummy */
.bookimgwraps {
  /* width: 355%; */
  /* height: 520px; */
  height: 458px;
}

.bookimgwraps img {
  width: 100%;
  height: 100%;
}

.booktext {
  margin: 10px auto 0;
  /* max-width: 190px; */
  padding: 0 20px;
}

.bookpara1 {
  color: #261e20;
  font-size: 12px;
  font-weight: 500;
  height: 42px;
  line-height: 18px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  display: inline;
}

.bookpara {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
  color: #261e20;
}

.author_name {
  color: #261e20;
}

/* photograph section */

#photograph {
  width: 100%;
  height: auto;
  padding: 30px 0px 60px;
  font-family: "Montserrat", sans-serif;
}

.photohead {
  font-size: 16px;
  line-height: normal;
  text-align: left;
  color: rgb(0, 0, 0);
  font-weight: 600;
  padding: 0;
  margin: 0 0 25px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

#photograph .flexwrapcard {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#photograph .photocard {
  width: 100%;
  max-width: 350px;
  height: auto;
  margin: 0 10px 30px;
}

.photoimgwrap {
  width: 100%;
  height: 280px;
}

.photoimgwrap img {
  width: 100%;
  height: 100%;
}

.phototext {
  padding-top: 15px;
}

.photopara1 {
  font-style: italic;
  font-size: 17px;
  height: 38px;
  color: #261e20;
}

.photopara {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
  color: #726e6e;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 55px;
}

.photosource {
  font-size: 13px;
  line-height: 18px;
  color: #726e6e;
}

.photolink {
  color: #726e6e;
  text-decoration: underline;
  text-decoration-color: #261e20;
}

.photolink:hover {
  color: #726e6e;
  text-decoration: underline;
  text-decoration-color: #261e20;
}

/* Documents section */

.docpara1 {
  color: #261e20;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

.docauthor_name {
  color: #726e6e;
  font-size: 12px;
}

/********StarupsNagaland_page*****/

.StarupsNagaland_page {
  width: 100%;
  height: auto;
  padding: 30px 0 60px;
}

.StarupsNagaland_page .head_line {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.StarupsNagaland_page .head_line h2 {
  font-size: 48px;
  line-height: normal;
  font-weight: 500;
  text-align: center;
  color: rgb(0, 0, 0);
  font-family: "Prata", serif;
  padding: 0;
  margin: 0;
}

.StarupsNagaland_page .nagaimg {
  width: 100%;
  max-width: 480px;
  height: 320px;
  overflow: hidden;
}

.StarupsNagaland_page .nagaimg img {
  width: 100%;
  height: 100%;
}

.StarupsNagaland_page .nagatext {
  width: 100%;
  height: auto;
}

.StarupsNagaland_page .nagatext p {
  font-size: 16px;
  line-height: 1.5;
  color: rgb(50, 50, 50);
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.Startups_tabarea {
  width: 100%;
  height: auto;
  padding: 20px 0 40px;
}

.Startups_tabarea .tabList {
  display: flex;
  width: 100%;
  /* max-width: 850px; */
  height: auto;
  margin-left: auto;
  background-color: rgb(249, 246, 240);
  margin-bottom: 10px;
  padding: 10px 10px;
  justify-content: flex-start;
  gap: 20px;
}

.swiper {
  position: static !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: -38px !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: -30px !important;
}

.Startups_tabarea .tabList .react-tabs__tab {
  max-width: 192px;
  text-align: center;
  font-size: 14px;
  /* color: rgb(161 158 158); */
  color: #000;
  font-family: Montserrat;
  font-weight: 400;
  background-color: transparent;
  /* border: 0 !important; */
  /* border-radius: 0px !important; */
  margin: 0px;
  padding: 5px 5px !important;
  /* border: 1px solid rgb(211 209 209) !important; */
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.Scholorships_tabarea .react-tabs__tab {
  white-space: nowrap;
  max-width: 100% !important;

}

.Startups_tabarea .tabList .react-tabs__tab--selected {
  /* background-color: #c9c9c9 !important; */
  color: rgb(168, 34, 34);
  bottom: -1px !important;
  display: flex !important;
  align-items: center;
  font-weight: 600;

  /* background: rgb(168, 34, 34) !important;
  color: #fff; */

}

.Startups_tabarea .tabList {
  overflow-x: auto;
}

.Startups_tabarea .tabList .react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
  display: none;
}

.Startups_tabarea .content_wrap {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.Startups_tabarea .content_wraps {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.Startups_tabarea .Startups_leftcontent {
  width: 100%;
  max-width: 320px;
  height: auto;
}

.Startups_tabarea .Startups_rightcontent {
  width: 100%;
  /* max-width: 810px; */
  max-width: 100%;
  height: auto;
  padding: 60px 0 40px;
}

.Startups_tabarea .cradbox_wrap {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.Startups_tabarea .cradbox_wrap .bookimg {
  width: 100%;
  overflow: hidden;
  margin-right: 30px;
  height: 158px;
  max-width: 235px;
  padding: 4px;
  align-items: center;
  border: 1px solid #d1cccc;
  display: flex;
  justify-content: center;
}

.bookimgwrap.mydocu {
  max-width: 152px;
  height: 188px;
  width: 100%;
}

.bookimgwrap.mybooks {
  max-width: 186px;
  height: 240px;
  width: 100%;
}

.Startups_tabarea .cradbox_wrap .bookimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  width: 225px;
  height: 148px;
  object-fit: cover;
}

.Startups_tabarea .cradbox_wrap .detailss {
  width: 100%;
  height: auto;
}

.Startups_tabarea .cradbox_wrap .name h4 {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  padding: 0;
  margin-bottom: 15px;
  font-weight: bold;
}

.Startups_tabarea .cradbox_wrap .text p {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  padding: 0;
  margin-bottom: 8px;
}

.Startups_tabarea .cradbox_wrap .morebtn_ .btn {
  font-size: 12px;
  line-height: 1.3;
  color: rgb(53, 53, 53);
  font-family: Montserrat;
  text-decoration: underline;
  padding: 0;
  margin: 0;
}

/****insights_page*****/

.insights_page {
  width: 100%;
  height: auto;
  padding: 40px 0;
}

.insights_page .head_line {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.insights_page .head_line h2 {
  font-size: 38px;
  color: rgb(53, 53, 53);
  font-weight: 400;
  line-height: normal;
  font-family: "Prata", serif;
  padding: 0;
  margin: 0 0 20px;
  text-align: center;
}

.insights_page .head_line p {
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: rgb(105, 104, 104);
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.gallery_area {
  width: 100%;
  height: auto;
}

.gallery_area img {
  width: 100%;
}

.gallery_area .column {
  width: 100%;
  height: auto;
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.gallery_area .column .img_wrap {
  cursor: pointer;
  flex: 28%;
  max-width: 30%;
}

.gallery_area .column .img_wrap.img1 {
  height: 300px;
}

.gallery_area .column .img_wrap.img2 {
  height: 400px;
}

.gallery_area .column .img_wrap.img3 {
  height: 340px;
}

.gallery_area .column .img_wrap.img4 {
  height: 300px;
  margin-top: -100px;
}

.gallery_area .column .img_wrap.img5 {
  height: 200px;
}

.gallery_area .column .img_wrap.img6 {
  height: 260px;
  margin-top: -60px;
}

.gallery_area .column img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*****commentmodal_area******/

.commentmodal_area {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.commentmodal_area .content_wrap {
  width: 100%;
  max-width: 700px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.commentmodal_area .content_wraps {
  width: 100%;
  max-width: 700px;
  height: auto;
  /* height: 585px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.commentmodal_area .close_btn .btn {
  position: absolute;
  top: 4px;
  right: 6px;
  font-size: 26px;
  padding: 0;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commentmodal_area .content_body {
  width: 100%;
  height: 480px;
  overflow-y: auto;
  padding: 60px 20px 100px;
}

.commentmodal_area .content_body::-webkit-scrollbar {
  display: none;
}

.commentmodal_area .givcomment_bar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  height: 100px;
}

.commentmodal_area .form-group {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
}

.commentmodal_area .form-group input {
  width: 100%;
  height: 45px;
  background-color: #d8d9db;
  box-shadow: none;
  outline: 0;
  border-radius: 24px;
  padding: 5px 20px;
}

.commentmodal_area .form-group .send_btn {
  position: absolute;
  top: 26%;
  right: 0px;
  transform: translate(-50%, 0%);
  z-index: 222;
  box-shadow: none;
  outline: 0;
  border: none;
  background-color: transparent;
  font-size: 22px;
}

.commentmodal_area .otherscomment {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 30px;
}

.commentmodal_area .profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.commentmodal_area .profile img {
  width: 100%;
  height: 100%;
}

.commentmodal_area .details {
  width: 100%;
  max-width: 70%;
  padding: 10px;
  border-radius: 4px;
  background-color: #e0dede;
  display: block;
}

.commentmodal_area .name h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  color: rgb(50, 50, 50);
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin-bottom: 5px;
}

.commentmodal_area .text p {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: rgb(50, 50, 50);
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
}

/*******passwordChange_modal*********/

.passwordChange_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.passwordChange_modal .modal_wrap {
  width: 100%;
  max-width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.passwordChange_modal .close_btn {
  position: absolute;
  top: 5px;
  right: 15px;
}

.passwordChange_modal .close_btn .btn {
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  font-size: 26px;
  padding: 0;
  margin: 0;
}

.passwordChange_modal .content_body {
  width: 100%;
  max-height: 540px;
  padding: 20px;
  overflow-y: auto;
}

.passwordChange_modal .content_body::-webkit-scrollbar {
  display: none;
}

.passwordChange_modal h4 {
  color: rgb(168, 34, 34);
  padding-bottom: 1em;
  font-family: "Prata", serif;
  font-weight: 500;
  text-align: left;
}

.passwordChange_modal form label {
  font-family: "Montserrat", sans-serif;
}

.passwordChange_modal form .form-control {
  /* background-color: #e5e2e9; */
  opacity: 0.7;
  margin-bottom: 1.5em;
  background-color: -internal-light-dark(rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4));
  box-shadow: none;
  outline: 0;
}

.passwordChange_modal .submit-btn {
  background-color: rgb(168, 34, 34);
  color: #ffffff;
  width: 100%;
  box-shadow: none;
  text-decoration: none;
  outline: 0;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  height: 45px;
  padding: 5px 15px;
  margin-top: 20px;
}

.passwordChange_modal .submit-btn:hover {
  background-color: #f2e8e4;
  color: rgb(168, 34, 34);
  border: 1px solid rgb(168, 34, 34);
}

/****audiopopup_area**/

.audiopopup_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000075;
  z-index: 999;
}

.audiopopup_area .wrapbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  height: 400px;
  border-radius: 4px;
  background-color: #fff;
  padding: 15px;
}

.audiopopup_area .wrapbox audio {
  width: 100%;
  /* height: 100%; */
  /* object-fit: cover; */
  border: 1px solid #535353;
}

.audiopopup_area .wrapbox .close_btn {
  position: absolute;
  top: 18px;
  right: 20px;
  border: none;
  outline: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fb041f;
  background-color: transparent;
  font-size: 24px;
  padding: 0;
}

/********/

.videopopup_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000075;
  z-index: 99;
}

.videopopup_area .wrapbox {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 100%; */
  /* max-width: 600px; */
  height: 80%;
  border-radius: 4px;
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.videopopup_area .wrapbox video {
  width: 100%;
  height: 415px;
  object-fit: cover;
  border: 1px solid #535353;
}

.videopopup_area .wrapbox .close_btn {
  position: absolute;
  top: 18px;
  right: 20px;
  border: none;
  outline: 0;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fb041f;
  background-color: transparent;
  font-size: 24px;
  padding: 0;
}

.activeDropTrive {
  background-color: #EDEDED;
}

.FolkCollectionCopyRight {
  display: flex;
  justify-content: end;
  margin: 1px 5px;
  font-size: 15px;
  font-family: sans-serif;
}

@media (max-width: 768px) {
  .gallery_area .column .img_wrap {
    flex: 48%;
    max-width: 50%;
    height: auto !important;
    margin-top: 0 !important;
  }
}

@media (max-width: 500px) {
  .gallery_area .column .img_wrap {
    flex: 100%;
    max-width: 100%;
  }
}

/**responsive-start****/
@media (max-width: 1399px) {
  .ptrnFig {
    margin-right: 65px;
  }

}

@media (max-width: 1366px) {
  .menu li {
    padding: 10px 15px;
  }

  .learnMFig {
    width: 65px;
    height: 50px;
  }

  .smallLogos {
    width: 28px;
    height: 28px;
    margin-right: 3px;
  }

  .smallDocText {
    font-size: 10px;
  }

  .booksAfterFig {
    width: 85px;
    height: auto;
  }
}

@media (max-width: 1199px) {
  .AthenMenu .menu li {
    padding: 10px 5px;
  }

  .menu li {
    padding: 10px 7px;
  }

  .menu li a {
    font-size: 12px;
  }

  .menuWrapper {
    max-width: 78%;
  }

  .bannerbox {
    height: auto;
    padding: 15px 15px 25px;
  }

  .feature p.para {
    font-size: 12px;
  }

  .feature h5.text {
    font-size: 15px;
  }

  .ptrnFig {
    margin-right: 35px;
  }

  .photoFig {
    cursor: pointer;
  }

  .photoFigDiv>div {
    width: 200px;
  }

  .left-content h5 {
    font-size: 15px;
  }

  .histTitle {
    left: 15px;
  }

  .photoSubFig {
    width: 255px;
    height: 165px;
    margin-bottom: 2px;
  }

  .photoSubAfterText {
    font-size: 12px;
  }

  .introimg {
    width: 100%;
    height: auto;
  }

  .aboutintro_btm {
    padding-top: 20px;
  }

  .ov_tophead {
    font-size: 30px;
  }

  .overviewimg-wrap {
    width: 95%;
  }

  .overview_number {
    font-size: 20px;
    margin-right: 10px;
  }

  .OV_head {
    font-size: 16px;
  }

  .ov_para {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 1025px) {
  .tabListTag {
    justify-content: flex-start;
  }

  .nagacontainer {
    padding: 4px 43px 6px 43px;
  }

  .feature .slick-next {
    top: 40%;
    right: -21px;
  }

  .feature .slick-prev {
    top: 40%;
    left: -29px;
  }
}

@media (max-width: 991px) {
  .rts___nav___btn___container {
    display: block !important;
    position: absolute;
    top: -10px;
  }

  .rts___nav___btn {
    background-color: #000;
  }

  .display___md___none {
    display: block !important;
  }

  .redBtn {
    display: none;
  }

  .about_bigimg .img_wrap {
    width: 100%;
    height: auto;
  }

  .thematicvideo_sec .allcard_wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .thematicvideo_sec .cardbox {
    background-color: rgb(249, 246, 240);
  }

  .main-flx {
    justify-content: start;
  }

  .img-box {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 30px auto 0;
  }

  .details {
    flex-wrap: wrap;
  }

  .myInput {
    width: 265px;
  }

  .bannerbox {
    width: 42%;
    height: auto;
    padding: 20px 25px;
    bottom: 0;
    right: 0;
  }

  #about_intro {
    padding: 20px 0px;
  }

  .aboutintro_body {
    padding: 0px 20px;
  }

  .introimg {
    width: 100%;
    height: 250px;
  }

  #about_points {
    padding: 15px 0px;
  }

  .about_pointsbody {
    padding: 0px 20px;
  }

  #overview {
    padding: 20px 0px;
  }

  .overview_body {
    padding: 0px 20px;
  }

  #blog {
    padding: 20px 0px;
  }

  .blog_body {
    padding: 0px 20px;
  }

  .historyleft {
    margin-right: 15px;
    flex-basis: 30%;
  }

  .blog_right {
    flex-basis: 70%;
  }

  .right_listitem {
    padding: 10px;
  }

  .history_lefthead {
    text-align: center;
  }

  #details {
    padding: 20px 0px;
  }

  .details_body {
    padding: 0px 20px;
  }

  .detailsright {
    flex-basis: 70%;
  }

  .details_head {
    font-size: 18px;
    line-height: 25px;
  }

  .details_bodyhead {
    font-size: 15px;
  }

  /* .feature .box {
    position: absolute;
    width: 273px;
  } */

  /* .imgfc {
    width: 47%;
  } */

  /* .collection .collec-img img {
    width: 100%;
  } */

  .collection .collec-txt {
    font-size: 17px;
  }

  .news_btm {
    padding: 0px 10px;
  }

  /* Contact part */
  #contact {
    width: 100%;
    height: auto;
    /* background-color: #E8E9EC; */
    background-color: white;
    color: #261e20;
    padding: 40px 0px;
  }

  .contact_body {
    padding: 0px 50px;
  }

  .contact_top {
    padding: 20px 30px;
    text-align: center;
  }

  .contact_top h2 {
    padding-bottom: 10px;
  }

  .contact_top h5 {
    color: #261e20;
    font-weight: bolder;
    padding: 10px 0px;
  }

  .contact_top p {
    color: #56565e;
    font-size: 15px;
  }

  .details {
    padding: 20px 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
  }

  .details_list p {
    font-size: 15px;
  }

  .details_list a i {
    font-size: 25px;
    color: #9f0213;
  }

  .contact_btm {
    padding: 20px 30px;
    background-color: #e8e9ec;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contact_form {
    flex-basis: 60%;
    margin-right: 20px;
  }

  .contact_form form {
    background-color: white;
    border-radius: 15px;
    padding: 20px 20px;
  }

  .form_text {
    padding-bottom: 20px;
  }

  .form_text h2 {
    line-height: 50px;
    font-weight: 600;
  }

  .form_text p {
    font-size: 15px;
  }

  .social_media h2 {
    line-height: 50px;
  }

  .social_media p {
    font-size: 15px;
  }

  label {
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin: 8px 0px;
  }

  .contact_form input {
    background-color: #e8e9ec;
    border: 1px solid grey;
    border-radius: 8px;
    width: 100%;
    height: 35px;
    padding: 10px 5px;
  }

  #msg {
    background-color: #e8e9ec;
    border: 1px solid grey;
    border-radius: 8px;
    resize: none;
    color: black;
  }

  input[type="email"],
  input[type="message"] {
    display: block;
  }

  input::placeholder {
    padding: 10px 5px;
    font-size: 15px;
  }

  .contact_body button {
    display: block;
    width: 100%;
    height: 45px;
    margin: 20px 0px;
    border: none;
    border-radius: 8px;
    background-color: #9f0213;
    color: white;
  }

  button:hover {
    background-color: #261e20;
    color: #e8e9ec;
  }

  .social_media {
    text-align: center;
    flex-basis: 40%;
  }

  .social_media h2 {
    padding: 15px 0px;
    font-weight: 600;
  }

  .social_media p {
    padding-bottom: 15px;
  }

  .social_media a i {
    color: #9f0213;
    font-size: 25px;
    letter-spacing: 20px;
  }

  .social_media a i:hover {
    color: #261e20;
  }

  .map {
    padding-top: 20px;
    width: 100%;
  }

  .map iframe {
    width: 100%;
  }

  .summary_left {
    padding: 15px 8px;
    margin-right: 30px;
  }

  .summary_right {
    padding: 15px 20px;
  }

  #history {
    padding: 20px 25px;
  }

  .citation {
    padding-left: 10px;
  }

  .rgtCnt {
    width: 100%;
    padding-left: 0;
  }

  .Header .menu li a {
    font-size: 15px;
  }

  .Header .menu li a {
    padding: 18px 12px;
  }

  .redBtn {
    font-size: 14px;
    width: 133px;
  }

  .ptrnFig {
    max-width: 260px;
    margin-right: 25px;
  }

  .homeFea {
    font-size: 13px;
    padding-bottom: 40px;
  }

  .footLogoDiv {
    display: block;
    margin-bottom: 15px;
  }

  .nagaFootHead {
    display: block;
    margin: 0 auto;
  }

  /* .photoFig {
    height: 110px;
  } */

  /* .photoFigDiv>div {
    width: 140px;
  } */
  .figAllDiv {
    margin-top: 20px;
  }

  .photoFigAfter {
    margin-top: 3px;
    font-size: 10px;
  }

  .photoSubFig {
    width: 162px;
    height: 118px;
    margin-bottom: -1px;
  }

  .photoSubAfterText {
    font-size: 10px;
  }

  .clouSmallHead {
    font-size: 11px;
  }

  .clouSmallDate {
    font-size: 8px;
  }

  .clouSmallText {
    font-size: 8px;
    line-height: 14px;
  }

  .introhead_btm {
    font-size: 20px;
    padding-bottom: 3px;
  }

  .intropara_btm {
    font-size: 14px;
  }

  .aboutintro_btm {
    padding-top: 15px;
  }

  .ov_tophead {
    font-size: 25px;
  }

  .bottom-header.bottomMob {
    display: none;
  }

  .toggolebar {
    display: block;
  }

  .hambarger {
    display: block;
  }

  .menu {
    display: none;
  }

  .Sidebar {
    display: block;
    background-color: rgb(168, 34, 34);
    width: 200px;
    height: 100vh;
    position: fixed;
    top: 7.48%;
    left: 0;
    z-index: 5;
    /* display: none; */
    padding-top: 10px;
    transform: translate(-250px, 0);
    transition-duration: all;
    transition-duration: 1s;
  }

  .Sidebar .mobMenu li {
    position: relative;
  }

  .Sidebar .mobMenu .submenu_drop {
    position: absolute;
    top: 92%;
    left: 0%;
    z-index: 11;
    background-color: #ccc;
    color: #9f0213;
    width: 100%;
    opacity: 0;
    border-radius: 0px;
    visibility: hidden;
  }

  .Sidebar .mobMenu .submenu_drop li a {
    font-size: 15px;
    font-weight: 600;
    color: #9f0213;
    padding: 5px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Sidebar .mobMenu li:hover .submenu_drop {
    opacity: 1;
    visibility: visible;
    position: relative;
    left: 50%;
  }

  .Sidebar .mobMenu li a {
    color: #e8e9ec;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }



  .Sidebar .mobMenu li:hover a i {
    transform: rotate(-180deg);
    transition: 0.6s;
  }

  .mySide .Sidebar {
    padding-top: 12px;
  }

  .hambarger:hover {
    color: #f8f8f8;
  }

  .footerUpdate {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767px) {

  .slick-prev:hover:before,
  .slick-next:hover:before {
    background-color: transparent !important;
  }

  .StarupsNagaland_page .nagatext {
    margin-top: 20px;
  }

  .contact_btm .left_arae {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .battle_Kohima .box_book {
    max-width: 45%;
  }

  .contactus_sec .row {
    margin: 0 !important;
  }

  .contactus_sec .rightimg {
    width: 100%;
    height: auto;
  }

  .accountOverlay {
    display: block;
  }

  .Sidebar {
    top: 7%;
    left: 0;
  }

  .partner {
    padding-bottom: 30px;
  }

  .Header {
    height: 60px;
  }

  .hambarger {
    font-size: 22px;
  }

  .overview_number {
    font-size: 20px;
  }

  .aboutintro_body {
    padding: 0px 10px;
  }

  .aboutintro_btm {
    flex-direction: column;
  }

  .aboutintro_left {
    width: 100%;
  }

  .about_pointsbody {
    padding: 0px 10px;
  }

  .introimg {
    width: 100%;
    height: auto;
  }

  .intro_text {
    width: 100%;
    margin-top: 10px;
  }

  .overview_body {
    padding: 0px 10px;
  }

  .overview_btm {
    flex-direction: column;
  }

  .overview_left {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .overviewimg-wrap {
    margin: 0px auto;
  }

  .overview_right {
    width: 100%;
  }

  /* .mainRemain {
    padding-top: 65px;
  } */

  .redBtn {
    width: 165px;
    margin-top: 10px;
  }

  .history_left {
    margin-right: 0;
  }

  .mt-5 {
    margin-top: 1rem !important;
  }

  #history {
    padding: 15px 0px;
  }

  .blog_body {
    padding: 0px 3px;
    flex-direction: column-reverse;
  }

  .historyleft {
    margin-right: 0px;
    margin: 10px 0px;
  }

  .hist_imgwrapper {
    width: 250px;
    height: 250px;
  }

  .right_listitem {
    margin-bottom: 10px;
  }

  .history_date {
    padding: 5px 0px;
  }

  #details {
    padding: 15px 0px;
  }

  .details_body {
    padding: 0px 3px;
    flex-direction: column-reverse;
  }

  .details_img {
    height: 250px;
  }

  .details_para {
    font-size: 13px;
  }

  .details_bodyhead {
    padding: 8px 0px;
  }

  .details_bodypara {
    font-size: 13px;
    padding-bottom: 5px;
  }

  .details_bodylist ul li {
    font-size: 13px;
  }

  .booklink {
    margin: 10px 0px;
    font-size: 13px;
  }

  .detailsgrey-rule {
    margin: 5px 0px;
    width: 150px;
  }

  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .feature {
    padding: 10px 0 30px;
  }

  .feature.featuredsec {
    padding: 30px 0;
  }

  .myInput {
    margin-top: 0;
    width: 80%;
  }

  .leftCnt {
    padding-left: 0;
  }

  .banner {
    padding-top: 1px;
    padding-bottom: 26px;
    height: auto;
  }

  .imgfc {
    width: 100%;
  }

  /* img.partner-logo {
    width: 45px;
    height: 45px;
  } */

  /* .sub-flex {
    width: 50%;
  } */

  /* .main-flx {
    flex-wrap: wrap;
  } */

  .footer .box {
    margin-top: 10px;
  }

  .order2 {
    justify-content: center;
    display: flex;
  }

  .banner {
    height: auto;
  }

  .bannerWrap {
    height: auto;
    padding: 20px 15px;
  }

  .leftCnt {
    width: 100%;
  }

  .bannerbox {
    width: 100%;
    display: block;
    height: auto;
    margin-right: 0;
    position: relative;
    margin-top: 30px;
  }

  .redbg {
    display: inline-block;
    margin-top: 0;
    font-size: 13px;
  }

  .collection .colle {
    font-family: Open Sans;
    font-size: 26px;
  }

  .collection .para {
    font-size: 20px;
    line-height: 23px;
    margin-top: 0px;
  }

  .banner h2 {
    font-size: 26px;
  }

  .sian {
    padding: 30px 0;
  }

  h3.feco {
    /* font-size: 22px; */
    font-size: 30px;
    margin-bottom: 20px;
    line-height: normal;
  }

  .fact-para {
    margin-top: 0px;
  }

  .img-box {
    margin-top: 20px;
  }

  h3.sia {
    font-size: 22px;
    line-height: 34px;
  }

  .news_btm {
    padding: 0px;
  }

  .news_img {
    flex-basis: 30%;
    width: 300px;
    height: 120px;
  }

  .news_text {
    flex-basis: 70%;
  }

  .newshead {
    font-size: 16px;
  }

  .newspara {
    font-size: 13px;
  }

  .date {
    font-size: 10px;
  }

  .left-content {
    margin-bottom: 30px;
  }

  .history_body {
    flex-direction: column;
  }

  .mobMenu {
    overflow-y: auto;
  }

  .order4 {
    order: 4;
  }

  .contact_body {
    padding: 0px 10px;
  }

  .contact_top {
    padding: 10px 5px;
  }

  .contact_top h2 {
    font-size: 19px;
    font-weight: 600;
  }

  .contact_top h5 {
    font-size: 34px;
  }

  .details {
    padding: 15px 10px;
  }

  .details_list h5 {
    line-height: 10px;
  }

  .contact_btm {
    padding: 20px 15px;
  }

  .contact_form form {
    padding: 15px 15px;
  }

  label {
    font-size: 13px;
  }

  input {
    font-size: 12px;
  }

  input::placeholder {
    font-size: 12px;
    font-weight: 500;
  }

  #msg {
    font-size: 12px;
  }

  .details_list h5 {
    font-size: 18px;
  }

  .details_list a i {
    font-size: 22px;
  }

  .form_text h2 {
    font-size: 22px;
  }

  .social_media h2 {
    font-size: 22px;
  }

  .faq-box ul.faq-ul {
    width: 100%;
    margin-bottom: 20px;
  }

  .summary_body {
    padding: 0px 5px;
  }

  .summary_left {
    margin-right: 10px;
    padding: 10px 3px;
  }

  .summary_lefttop h3 {
    font-size: 16px;
  }

  .summary_lefttop a button {
    width: 170px;
  }

  .right_heading {
    font-size: 12px;
  }

  .summary_right {
    padding: 10px 15px;
  }

  .summary_right h3 {
    font-size: 18px;
  }

  .summary_right p {
    font-size: 13px;
  }

  .left-content p {
    font-weight: 500;
    font-size: 14px;
  }

  .left-content h5 {
    font-weight: 700;
    font-size: 18px;
  }

  .list-item {
    padding: 10px;
  }

  .list_text h6 {
    font-size: 15px;
  }

  .list_text p {
    font-size: 13px;
  }

  /* .mainBody {
    padding-top: 157px;
  } */

  .header2 {
    height: 157px;
  }

  .footer {
    text-align: center;
  }

  /* .feature .box {
    max-width: 414px;
    width: 100%;
    left: 0;
  } */

  .order1 {
    order: 1;
  }

  .order2 {
    order: 2;
    display: inline-block;
  }

  .order3 {
    order: 3;
  }

  /* .ptrnFig {
    max-width: 180px;
    margin-right: 20px;
  } */

  .readMoreLink {
    font-size: 20px;
  }

  .topGetInClass {
    margin-bottom: 15px;
  }

  .getInRowClass {
    margin-top: 7px;
  }

  .getInTouch {
    margin: 20px 0;
  }

  .getSubBtn {
    font-size: 18px;
    padding: 8px 40px;
  }

  .nameInps {
    font-size: 14px;
  }

  .getFrmInps {
    font-size: 14px;
  }

  .nagaFootHead {
    font-size: 25px;
    line-height: 21px;
  }

  .digiText {
    font-size: 16px;
  }

  .footLogoFig {
    width: 45px;
    height: 45px;
    margin: 5px auto;
  }

  .footRightLiAn {
    font-size: 13px;
  }

  .footRightLiAn_updated {
    font-size: 13px;
    white-space: wrap;
    /* text-wrap: wrap; */
  }

  .footSocialLogo {
    font-size: 16px;
    margin-right: 20px;
  }

  .footerRightTextDiv {
    width: 170px;
  }

  .footerRightLogText {
    font-size: 12px;
  }

  .footerUpdate {
    padding: 32px 25px;
  }

  .footRightLi {
    margin-bottom: 0px;
  }

  .footCpyText {
    font-size: 13px;
    text-align: center;
  }

  .myInpDiv {
    margin-top: 20px;
  }



  .feature .detailss {
    font-size: 14px;
  }

  .Sidebar .mobMenu li a {
    padding: 10px 15px;
    font-size: 12px;
  }

  .nagacontainer {
    padding: 5px 30px;
  }
  .homeFea {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .folkloreDetails_Page .top_area_img .image {
    width: 685px;
    height: 260px;
  }

  .listimg_wrapper {
    margin: 0 auto;
  }

  .list_text {
    margin-left: 0;
    margin-top: 10px;
  }

  .about_bigimg {
    padding: 0 0 15px 0;
  }

  .feature.featuredsec .imgfc {
    margin: 0 5px 15px;
  }

  .headpagination h2 {
    font-size: 28px;
  }

  .history_head {
    font-size: 22px;
  }

  .battle_Kohima .box_book {
    max-width: 100%;
  }

  .right_listtext {
    flex-basis: 100%;
  }

  .history_para {
    font-size: 13px;
    padding: 2px 0px;
  }

  .Contributing {
    margin-left: 5px;
  }

  .hist_imgwrapper {
    width: 180px;
    height: 180px;
  }

  .right_listitem {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .right_listimg {
    flex-basis: 100%;
    height: auto;
    margin-bottom: 15px;
    width: 100%;
    margin-right: 0;
  }

  .details_head {
    font-size: 16px;
    padding-bottom: 7px;
    line-height: 20px;
  }

  .details_img {
    height: auto;
    width: 100%;
  }

  .details_text {
    padding: 15px 0;
  }

  .details_text p {
    font-size: 13px;
  }

  .bookref_imgwrap {
    width: 50px;
    height: 50px;
  }

  .news_top {
    padding: 20px 0px;
  }

  .tophead {
    font-size: 25px;
  }

  .newshead {
    font-size: 15px;
  }

  .news_list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }

  .news_img {
    width: 100%;
  }

  .news_text {
    padding-left: 0;
    margin-top: 20px;
  }

  .date {
    margin-top: 10px;
  }

  .contact_body {
    padding: 0px 0px;
  }

  .details {
    padding: 10px 5px;
  }

  .details_list {
    margin-right: 5px;
  }

  .details_list a i {
    font-size: 18px;
  }

  .details_list h5 {
    font-size: 15px;
  }

  .details_list p {
    font-size: 13px;
  }

  .contact_btm {
    flex-direction: column;
  }

  .form_text {
    text-align: center;
  }

  .contact_form {
    margin-right: 0px;
  }

  .contact_body button {
    font-size: 15px;
    height: 35px;
  }

  .social_media a i {
    font-size: 18px;
  }

  .summary_body {
    flex-direction: column;
    /* padding: 0px; */
  }

  .summary_left {
    margin-right: 0px;
    padding: 10px 0px;
    width: 100%;
  }

  .summaryimg-wrapper {
    width: 150px;
    height: 200px;
  }

  #history {
    padding: 20px 10px;
  }

  .history_left {
    max-width: 100%;
  }

  .collectionTop {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .ptrnFig {

    margin-bottom: 5px;
    max-width: 100%;

    margin: 20px 0;
  }

  .collectionTop {
    padding: 5px 0 0px;
  }

  .ptrnFig2 {
    max-width: 250px;
    margin-right: 7px;
  }

  .footerUpdate {
    padding: 25px 20px;
  }

  .footRightLiAn {
    font-size: 11px;
  }

  .footRightLiAn_updated {
    font-size: 11px;
    white-space: wrap;
  }

  .footerRightLogText {
    font-size: 11px;
  }

  .footCpyText {
    font-size: 11px;
  }

  .leftCnt {
    padding-top: 15px;
  }

  .myInpDiv {
    margin-top: 0px;
  }

  .banner_contribute {
    position: relative;
    left: 0;
    padding: 25px 0;
  }

  .contribute_btn {
    justify-content: start;
  }

  .bannerbox p {
    font-size: 14px;
    height: auto;
  }

  .banner h1 {
    line-height: normal;
    margin-bottom: 30px;
  }
}

@media (max-width:544px) {
  .redbg {
    padding: 0 5px;
    width: 30%;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .myInput input::placeholder {
    font-size: 10px;

  }

  .myInput input {
    padding: 0 0 0 33px;
  }

  .abt_pointhead {
    margin-bottom: 5px;
    font-size: 24px;
  }

  .bookimgwrap {
    width: 100%;
    height: 150px;
  }

  /* dummy */

  .bookimgwraps {
    width: 100%;
    height: 150px;
  }

  .intropara_btm {
    margin-bottom: 30px;
  }

  .aboutintro_btm {
    padding-top: 0px;
  }

  .introhead {
    font-size: 30px;
    margin-top: 20px;
  }

  .bradecum_head .head h4 {
    font-size: 28px;
  }

  .bradecum_head .text p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }

  .seemore_div {
    justify-content: center !important;
    padding: 20px 0 15px;
  }

  .banner h1 {
    font-size: 30px;
  }

  .leftCnt .pp {
    font-size: 25px;
  }

  .rgtCnt img {
    width: 142px;
  }

  .logo img {
    width: 100%;
  }

  /* .feature .box {
    width: 85%;
  } */

  .feature h5.text {
    font-size: 13px;
    height: auto;
  }

  .feature p.para {
    font-size: 14px;
  }

  .readMoreLink {
    font-size: 14px;
  }

  .ptrnFig2 {
    max-width: 140px;
    margin-right: 5px;
  }

  .getInTextP {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .footerUpdate {
    padding: 20px 5px;
  }

  .footRightDiv {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footerRightUl {
    margin-right: 25px;
  }

  .footRightLogoDiv {
    margin-top: 12px;
  }

  .footerRightTextDiv {
    width: 150px;
  }

  .nagaFootHead {
    font-size: 21px;
    line-height: 18px;
  }

  .digiText {
    font-size: 14px;
  }

  .footLogoFig {
    width: 40px;
    height: 40px;
  }

  .footCpyText {
    text-align: center;
  }

  .redbg {
    padding: 0 5px;
    width: 30%;
    font-size: 12px;
  }

  .myInput {
    width: 80%;
  }

  /* .photoFig {
    height: 80px;
  } */

  /* .photoFigDiv>div {
    width: 96px;
  } */

  .photoFigAfter {
    margin-top: -1px;
    font-size: 7px;
  }

  .photoSubFig {
    width: 140px;
    height: 95px;
  }

  .clouSmallHead {
    margin-bottom: 0;
  }

  .clouSmallDate {
    margin-bottom: 0px;
  }

  .banner h1 span {
    line-height: normal;
  }

  .bannerbox a {
    font-size: 16px;
  }

  .bannerbox_head {
    font-size: 18px;
  }

  .menuWrapper {
    max-width: 20%;
  }
  .breadcrumbs-header nav a {
    font-size: 10px;
  }
}

@media (max-width: 420px) {
  .logo {
    max-width: 212px;
    height: auto;
  }
}

@media only screen and (min-width: 200px) and (max-width: 1199px) {
  .redBtn {

    width: 107px;

    font-size: 11px;

  }

  .left-content {
    width: 100%;
  }

  .flx {
    flex-wrap: wrap;
  }

  .img-box img {
    margin: auto;
    width: 100%;
  }

  h5.partner-name {
    font-size: 13px;
  }
}

.Skeleton-Loader {
  display: flex;
}

/* responsive */

@media (max-width: 991px) {
  .ptrnFig {
    width: 100%;
  }

  /* books section */
  .bannerWrap {
    flex-wrap: wrap;
  }

  .main-flx {
    padding-top: 0 !important;
  }

  .booksflex {
    flex-wrap: wrap;
  }

  .booksdiv {
    margin-bottom: 15px;
  }

  .bookshead {
    margin-bottom: 20px;
  }

  /* photo section */

  .photopara1 {
    height: auto;
    margin-bottom: 8px;
  }

  .photocard {
    margin-bottom: 15px;
  }

  .photohead {
    margin-bottom: 20px;
  }

  .phototext {
    margin-top: 5px;
  }
}

@media (max-width: 767px) {
  .passwordChange_modal .modal_wrap {
    width: 95%;
  }

  .Startups_tabarea {
    padding: 20px 0 15px;
  }

  .StarupsNagaland_page .head_line h2 {
    font-size: 20px;
  }

  .StarupsNagaland_page .head_line {
    margin-bottom: 15px;
  }

  .Startups_tabarea .Startups_rightcontent {

    padding: 25px 0 0px;
  }

  .StarupsNagaland_page {
    padding: 15px 0 10px;
  }

  .sammry_sec .left_aera {

    height: 260px;
  }

  .sammry_sec .left_aera img {
    object-fit: cover;
    height: 100%;
  }

  .sammry_sec .right_area {
    margin-top: 20px;
  }

  .sammry_sec .right_area p {
    font-size: 14px;
  }

  #photograph {

    padding: 15px 0px 10px;
  }

  #books {

    padding: 20px 0 15px;
  }

  .sammry_sec {
    padding: 0px 0 15px;
  }

  .thematicdetails_page .headofthe_page h2 {
    font-size: 24px;
  }

  .thematicdetails_page .headofthe_page {

    padding: 15px 0 15px;
  }

  .feature.featuredsec .swiper-button-prev,
  .feature.featuredsec .swiper-button-next {
    top: 30%;
  }

  .feature .imgfc {
    margin: 0 10px 15px;
  }

  /* books section */

  .booksflex {
    justify-content: flex-start;
  }

  .booksdiv {
    width: 40%;
    margin: 10px;
  }

  .bookpara1 {
    height: auto;
    margin-bottom: 10px;
  }
}

@media (max-width: 575px) {

  .sian .img-box {
    width: 100%;
    height: auto;
  }

  .sian .img-box img {
    object-fit: contain;
  }

  .commentmodal_area .content_wraps {
    width: 95%;
  }

  .videopopup_area .wrapbox {
    width: 90% !important;
  }

  /* books section */
  .list-item {
    flex-wrap: wrap;
  }

  .booksdiv {
    width: 50%;
    max-width: 200px;
  }

  .booksflex {
    justify-content: space-around;
  }

  /* photo section */

  .phototext {
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .feature.myPhotos .slick-prev {
    top: 25%;
  }

  .feature.myPhotos .slick-next {
    top: 25%;
  }

  .thematicvideo_sec .cardbox {
    max-width: 100%;
    margin: 0;
  }

  /* books section */
  .booksdiv {
    /* max-width: 190px; */
    max-width: 100%;
  }
}

/* report pagination */
.report-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.report-pagination input {
  width: 42px;
  border-radius: 11px;
  margin: 0 2px;
  text-align: center;
}

.report-pagination button {
  padding: 3px 10px;
  /* background-color: lightblue; */
  border-radius: 7px;
  background: linear-gradient(to right, rgb(168 35 34), rgb(168 35 34));
  color: #fff;
  margin: 0 3px;
}

.page-no-report {
  background: linear-gradient(to right, rgb(168 35 34), rgb(168 35 34));
  color: #fff;
  line-height: 21px;
  height: 23px;
  padding: 2px 8px;
}

.type-modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.type-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.type-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.type-close:hover,
.type-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* .slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */

/* @-webkit-keyframes slide-top {
  0% {

    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {

    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
} */

.myOwnSlickSlide .slick-slide div {
  display: flex !important;
  justify-content: center !important;
}

@media (max-width: 480px) {
  /* .feature.featuredsec .imgfc {
    max-width: 200px;
  } */

  .myPerSwiper .swiper-slide {
    display: flex;
    justify-content: center;
  }
}

.skeleton-loader:empty {
  width: 100%;
  height: 15px;
  display: block;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0;
  }
}

.prod--wrapper {
  display: flex;
  width: 95%;
  margin: 32px 0;
  border: 1px solid #b6b6b6;
  border-radius: 6px;
  padding: 22px 10px;
  font-family: "Calibri", "Arial";
}

.prod--wrapper .prod--row {
  display: flex;
  flex-direction: row;
}

.prod--wrapper .prod--col {
  display: flex;
  flex-direction: column;
}

.prod--wrapper .prod--img {
  width: 20%;
  margin: 0 15px;
}

.prod--wrapper .prod--img .prod--img-graphic {
  max-height: 100%;
  height: 100%;
  vertical-align: top;
  max-width: 100%;
}

.prod--wrapper .prod--details {
  width: 90%;
  margin-left: 17px;
}

.prod--wrapper .prod--details .prod--name {
  margin-bottom: 3px;
  width: 85%;
  display: block;
  max-width: 100%;
}

.prod--wrapper .prod--details .prod--name .prod--name-para {
  margin: 0 auto;
}

.prod--wrapper .prod--details .prod--name .prod--name-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #002877;
  height: 40px;
}

.prod--wrapper .prod--details .prod--description {
  margin-bottom: 13px;
}

.prod--wrapper .prod--details .prod--description .prod--description-text {
  font-size: 13px;
  line-height: 18px;
  color: #666666;
}

@media (max-width: 767px) {
  .detailsright .details_rightcontent {
    max-width: 100%;
    height: auto;
    padding: 0;
  }
}


/***************************/
/*****folklore_nagaland****/

.folklore_nagaland {
  width: 100%;
  height: auto;
  background-color: rgb(249, 246, 240);
  padding: 30px 0;
}

.folklore_nagaland .leftara {
  width: 100%;
  height: auto;
  padding: 35px 0px 20px;
}

.folklore_nagaland h3.feco {
  font-family: "Prata", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  color: rgb(53, 53, 53);
  margin-bottom: 30px;
  margin-top: 15px;
  line-height: 68px;
  font-weight: 500;
}

.folklore_nagaland p.fact {
  margin-bottom: 80px;
}

.folklore_nagaland .rightarea {
  width: 100%;
  height: auto;
  padding: 20px 0px;
}

.folklore_nagaland .rightarea .folklore_img {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-left: auto;
}

.folklore_nagaland .rightarea .folklore_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



@media (max-width: 767px) {
  .folklore_nagaland h3.feco {
    font-size: 22px;
    margin-bottom: 20px;
    line-height: normal;
    text-align: center;
  }

  .folklore_nagaland p.fact {
    margin-bottom: 0px;
  }

  .folklore_nagaland .leftara {
    padding: 30px 0px 20px;
  }

  .folklore_nagaland {
    padding: 20px 0;
  }

}

@media (max-width:600px) {
  .Startups_tabarea .cradbox_wrap {
    flex-wrap: wrap;
  }

  .Startups_tabarea .cradbox_wrap .bookimg {
    margin: 0 auto 20px auto;
  }
}



/*****folkloreDetails_Page*****/

.folkloreDetails_Page {
  width: 100%;
  height: auto;
  padding-bottom: 60px;
}

.folkloreDetails_Page .top_area {
  padding-top: 40px;
}

.folkloreDetails_Page .top_area .left {
  width: 66%;
  height: auto;
}

.folkloreDetails_Page .top_area .right {
  width: 30%;
  height: auto;
}

.folkloreDetails_Page .top_area .left .name {
  width: 100%;
  height: auto;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
}

.folkloreDetails_Page .top_area .left .name .marginright {
  margin-right: 30px;
}

.folkloreDetails_Page .top_area .left .name .marginright h3 {
  font-size: 24px;
  font-family: "Prata", serif;
}

.folkloreDetails_Page .top_area .left .name .marginright h6 {
  font-size: 14px;
  font-family: Montserrat;
}

.folkloreDetails_Page .top_area .left .name p {
  font-size: 12px;
  font-family: Montserrat;
}

.folkloreDetails_Page .image {
  height: 330px;

  width: auto;
}

.folkloreDetails_Page .top_area_img .image {
  width: 685px;
  height: 330px;
}

.folkloreDetails_Page .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.ck_para {
  float: right;
}

.ck_para p {
  font-size: 14px;
  font-family: Montserrat;
}

.Img_2nd {
  float: left;
  padding: 20px 20px 20px 0;
}

.folkloreDetails_Page .top_area_img {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.folkloreDetails_Page .Img_right_area .top>div {
  gap: 20px;
}

.folkloreDetails_Page .Img_right_area .top>div>p:nth-child(1) {
  width: 75px;

}

.folkloreDetails_Page .Img_right_area .top>div>p:nth-child(2) {
  margin-left: 10px;
  width: calc(100% - 75px);

}

.space_top {
  padding: 0 70px;
}

.folkloreDetails_Page .Img_right_area .top p {
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat;
}

.folkloreDetails_Page .Img_right_area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 36%;
}

.folkloreDetails_Page .para p {
  margin: 20px 0;
}

.Img_2nd_part {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.Img_2nd_part .Img_2nd {
  width: 40%;
  height: 263px;
}

.Img_2nd_part .Img_2nd img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.Img_2nd_part .para {
  width: 55%;
}

.readBtn {
  color: rgb(168, 34, 34);
  text-decoration: underline;
}

.readBtn:hover {
  color: rgb(168, 34, 34);
}

.folkloreDetails_Page .box_book .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000002f;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
}

.onImgeText {
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding: 10px;
}

.onImgeText h5 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
  height: 35px;
  overflow: hidden;
}

.onImgeText h6 {
  color: #fff;
  font-size: 13px;
}

.folkloreDetails_Page .box_book:hover .overlay {
  opacity: 1;
}

.folkloreDetails_Page .box_book {
  position: relative;
  height: 167px;
}

.folkloreDetails_Page .box_book img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.folkloreDetails_Page .slick-initialized .slick-slide {
  padding: 0 5px;
}

.All_link a {
  display: block;
  text-decoration: underline;
  margin-bottom: 10px;
  font-family: Montserrat;
}

.folkloreDetails_Page .slick-prev {
  left: -32px;
}

.privacy-policy {
  padding-left: 48px;
}

.terms-area p strong {
  font-size: 18px;
}

.terms-area h5 {
  font-size: 18px;
  font-weight: bold;
}

.terms-area p {
  font-size: 14px;
}

@media (max-width:767px) {
  .folklgBtn {
    display: none;
  }

  .folkmbBtn {
    display: flex !important;
    visibility: visible;
  }

  .faq-box ul.faq-ul li.faq-li span.faq-desc {
    font-size: 14px;
  }

  .about_pointpara {

    font-size: 14px;
    line-height: 22px;
  }

  #about_intro {
    padding: 0;
  }

  .folklore_nagaland .rightarea .folklore_img img {
    object-fit: contain;
  }

  .space_top {
    padding: 0;
  }

  .folkloreDetails_Page .top_area .left .name .marginright h3 {
    font-size: 18px;
  }

  .battle_Kohima .box_book {
    margin: 0 6px 15px;
  }

  .battle_Kohima {

    padding: 15px 0 14px;
  }

  .folkloreDetails_Page .Img_right_area {
    width: 100%;
    gap: 10px;
  }

  .folkloreDetails_Page .top_area_img {
    flex-wrap: wrap;
  }

  .folkloreDetails_Page .image {
    margin: 0 auto;
  }

  .Img_2nd_part {
    flex-wrap: wrap;
  }

  .Img_2nd_part .para {
    width: 100%;
  }

  .Img_2nd_part .Img_2nd {
    margin: 0 auto;
  }

  .All_link {
    display: flex;
    gap: 15px;
    overflow-x: auto;

  }

  .folkloreDetails_Page .top_area .left {
    width: 100%;
  }

  .folkloreDetails_Page .top_area .left .name {
    justify-content: center;
  }
}

@media (max-width:494px) {
  .folkloreDetails_Page .top_area .left .name {
    flex-wrap: wrap;
  }

  .folkloreDetails_Page .top_area .left .name .marginright {
    margin-right: 0;
    width: 100%;
  }

  .folkloreDetails_Page .image {
    width: 100%;
  }

  .Img_2nd_part .Img_2nd {
    width: 100%;
  }

}

@media (max-width:384px) {


  .details_text>div {
    flex-wrap: wrap;
  }

  .sammry_sec .left_aera {
    height: 225px;
  }
}

@media (max-width:360px) {
  .listimg_wrapper.video_list_wrapper>div>div.react-player {
    width: 13rem !important;
  }
}

@media (max-width: 1400px) {
  .folklore_nagaland .leftara {
    width: 100%;
    height: auto;
    padding: 20px 0px 20px;
  }
}

@media (max-width:991px) {
  .top-header.black_header {
    display: none;
  }

  .collectionTop {

    justify-content: end;

  }

  .sian .img-box {
    margin-bottom: 30px;
  }

  h3.feco {
    margin-bottom: 20px;
  }

  .contactus_sec .rightimg {
    margin-top: 15px;
  }

  .space_top {
    padding: 0;
  }

  .folklore_nagaland .rightarea .folklore_img {
    width: 100%;
    height: auto;
  }

  .folklore_nagaland h3.feco {
    font-family: "Prata", serif;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    color: rgb(53, 53, 53);
    margin-bottom: 20px;
    margin-top: 15px;
    line-height: 39px;
    font-weight: 500;
  }

  .folklore_nagaland .leftara {
    width: 100%;
    height: auto;
    padding: 30px 0px 20px;
  }
}

@media (max-width: 768px) {
  .folklore_nagaland .rightarea .folklore_img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .folklore_nagaland h3.feco {
    /* font-size: 22px; */
    font-size: 30px;
    text-align: left;
  }
  .folklore_nagaland h3.feco br {
    display: none;
  }
  .folklore_nagaland .leftara {
    padding: 0px 0px 20px;
}
}

@media (max-width: 575px) {
  .sian .img-box {
    margin-bottom: 10px;
  }

  p.fact {
    font-size: 13px;
  }

  .bannerWrap {
    padding: 20px 0;
  }

  .folklore_nagaland .rightarea {
    padding: 20px 0px;
  }
}

@media (max-width:380px) {
  .StarupsNagaland_page .nagaimg {

    height: 237px;
  }
}

@media (max-width:345px) {
  .history_head {
    font-size: 18px;
  }

  .footRightLogoDiv {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footerRightTextDiv {
    width: 100%;
    justify-content: space-between;
  }

  .StarupsNagaland_page .nagaimg {
    object-fit: cover;
    height: 189px;

  }
}

@media (max-width:330px) {
  .redbg {
    padding: 0 5px;
    width: 40%;
    font-size: 10px;
  }
}

@media (max-width:320px) {
  .sammry_sec .left_aera {
    height: 184px;
  }
}




/*****Partnersdescription_area*****/

.Partnersdescription_area {
  width: 100%;
  height: auto;
  padding: 20px 0;
}

.Partnersdescription_area .card_box {
  width: 100%;
  height: auto;
  padding: 15px;
}

.Partnersdescription_area .logoimg {
  width: 100%;
  max-width: 95px;
  min-width: 95px;
  height: 95px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 15px;
}

/* .Partnersdescription_area .col-md-6:nth-child(4) .logoimg {
  max-width: 140px;
  min-width: 100px;
  height: auto;
}

.Partnersdescription_area .col-md-6:nth-child(6) .logoimg {
  max-width: 140px;
  min-width: 100px;
  height: auto;
} */

.Partnersdescription_area .logoimg img {
  width: 100%;
  height: 100%;
}

.Partnersdescription_area .description {
  font-size: 14px;
  line-height: normal;
  text-decoration: none;
  text-align: left;
  color: #302d2d;
  letter-spacing: 0.5px;
}

.Partnersdescription_area .description strong {
  text-decoration: underline;
  margin-right: 6px;
}

/***PhotoCredit_block**/
.PhotoCredit_block {
  width: auto;
  height: auto;
  /* background: linear-gradient(337.81deg, #17323B 20.04%, #000000 62.49%); */
  color: #fff;
  padding: 5px 0;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.PhotoCredit_block .text {
  font-size: 10px;
  line-height: normal;
  font-weight: 500;
  color: #c0bebe;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PhotoCredit_block .text span {
  /* font-size: 12px; */
  margin-right: 6px;
  text-transform: uppercase;
}

.PhotoCredit_block .text a {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  color: #c0bebe;
}


@media (max-width: 480px) {
  .PhotoCredit_block {
    padding: 3px 0;
  }

  .PhotoCredit_block .text {
    font-size: 10px;
  }

  .PhotoCredit_block .text span {
    /* font-size: 10px; */
    margin-right: 5px;
  }

  .PhotoCredit_block {
    right: 0px;
    bottom: 0px;
  }
}