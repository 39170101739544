/***FolklorePageNew***/

/* banner-section */
.banner-section {
  width: 100%;
  height: 433px;
  position: relative;
}

.banner-img-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner-image {
  width: 100%;
  height: 100%;
}

.banner-img-box:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.banner-main-box {
  width: 100%;
  position: relative;
  margin-top: 8rem;
}

.banner-text {
  width: 40%;
}

.banner-title {
  width: 100%;
  font-size: 45px;
  padding-bottom: 15px;
  color: #fff;
  font-family: "Prata";
  font-weight: 500;
}

.banner-desc {
  color: #fff;
  font-size: 20px;
  font-weight: 450;
  line-height: normal;
}


/*****new banner******/
.folklore_banner {
  width: 100%;
  height: auto;
  background: linear-gradient(337.81deg, #17323B 20.04%, #000000 62.49%);
  color: #fff;
  /* padding: 80px 0; */
  position: relative;
}

.folklorebackimgwrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.folklorebackimgwrap img {
  width: 100%;
  height: 100%;
}

.folklore_banner .bannerimg_wrap {
  width: 100%;
  height: auto;
  margin-left: auto;
  padding: 60px;
}

.folklore_banner .bannerimg_wrap img {
  width: 100%;
  height: 100%;
}

.folklore_banner .content {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
}

.folklore_banner .lefttext {
  padding: 60px 0;
}

.folklore_banner .heading {
  font-family: Prata;
  font-size: 48px;
  font-weight: 400;
  line-height: 65.04px;
  text-align: left;
  margin-bottom: 20px;
}

.folklore_banner .sub_heading {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
  margin-bottom: 20px;
}

.folklore_banner .text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: justified;
  margin-bottom: 30px;
}

/* folk-about */

.folk-about {
  width: 100%;
  height: 433px;
  background-color: #f9f6f0;
  padding: 20px 30px;
}

.about {
  height: 100%;
}

.about-main-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 15px;
  padding: 25px 0;
}

.folk-about-title {
  font-size: 40px;
}

.folk-about-mini-title {
  font-size: 15px;
  font-weight: 600;
  padding-top: 10px;
  font-family: "Montserrat";
}

.about-desc-box {
  width: 76%;
  text-align: center;
  font-family: "Montserrat";
}

.folk-about-desc1 {
  padding-bottom: 15px;
}

/* Folk-Volume section */
.folk-volume-section {
  width: 100%;
  padding: 50px 0;
}

.folk-volume-main-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.volume-main-section {
  width: 100%;
  /* max-width: 592px; */
  max-width: 50%;
  height: auto;
}

.folk-volume-title {
  font-size: 40px;
  font-family: "Prata", serif;
  font-weight: 400;
  text-align: left;
  line-height: normal;
  padding: 0;
  margin-bottom: 15px;
}

.folk-volume-img {
  width: 100%;
  height: 296px;
  overflow: hidden;
  border-radius: 12px;
}

.folk-volume-img img {
  width: 100%;
  height: 100%;
}

.vol-desc {
  width: 100%;
  padding-top: 10px;
  font-size: 15px;
  font-family: "Montserrat";
  color: #39373A;
  font-weight: 500;
  line-height: 18.29px;
  padding-bottom: 10px;
}




/* contributor section */
.contributor-section {
  font-family: "Montserrat";
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.totalcontentwrap {
  width: 100%;
  height: 561px;
  position: relative;
}

.contributor-main {
  width: 50%;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.contributor-title {
  font-family: Prata;
  font-size: 48px;
  font-weight: 400;
  line-height: 65.04px;
  text-align: left;
  margin-bottom: 20px;
}

.contributor-desc {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
  margin-bottom: 30px;
}

.contributor-btn {
  color: hsl(0, 66%, 40%);
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  border: none;
  padding: 10px 18px 10px 18px;
  background-color: #fff;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  cursor: pointer;

}

.contributor-btn:hover i#contri-btn-icon {
  transform: translateX(5px);
  transition: 0.4s ease-in-out;
}

/* Responsive */
@media (max-width: 1336px) {

  .contributor-title {
    font-size: 43px;
  }

  .contributor-desc {
    width: 53%;
    font-size: 15px;
  }

  .contributor-btn {
    padding: 6px 12px;
  }
}

@media (max-width: 1199px) {

  /* banner section */
  .banner-section {
    width: 100%;
    height: 300px;
  }

  .banner-text {
    width: 45%;
  }

  .banner-main-box {
    padding: 0 50px;
    margin-top: 7rem;
  }

  .folk-volume-title {
    font-size: 36px;
  }

  /* contributor section */


  .contributor-title {
    font-size: 40px;
  }

  .contributor-desc {
    width: 55%;
    font-size: 15px;
  }

  .contributor-btn {
    padding: 8px 12px;
  }
}

@media (max-width: 1024px) {

  /* Folk-Volume section */
  .folk-volume-title {
    font-size: 32px;
  }

  /* contributor section */

  .contributor-title {
    font-size: 38px;
  }

  .contributor-desc {
    width: 70%;
    font-size: 15px;
  }

  .contributor-btn {
    padding: 8px 12px;
  }
}

@media (max-width: 992px) {

  /* banner section */
  .banner-section {
    width: 100%;
    height: auto;
    position: relative;
  }

  .banner-text {
    width: 50%;
    position: relative;
    padding: 80px 0;
  }

  .banner-main-box {
    margin-top: 5rem;
  }

  .banner-title {
    font-size: 40px;
    padding-bottom: 15px;
  }

  .banner-desc {
    font-size: 17px;
  }

  /* about section */
  .folk-about {
    width: 100%;
    height: auto;
  }

  .about-desc-box {
    width: 100%;
  }

  /* folk-vol section */
  .folk-volume-section {
    padding: 50px 0;
  }

  .folk-volume-title {
    font-size: 28px;
  }

  .inner-title {
    font-size: 19px;
    font-weight: bold;
  }

  /* contributor section */


  .contributor-title {
    font-size: 34px;
    width: 100%;
  }

  .contributor-desc {
    width: 85%;
    font-size: 15px;
  }

  .contributor-btn {
    padding: 7px 12px;
  }

  .folklore_banner .heading {
    font-size: 28px;
    line-height: normal;
    margin-bottom: 15px;
  }

  .folklore_banner .sub_heading {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .folklore_banner .text {
    font-size: 12px;
    margin-bottom: 20px;
  }

  /* .folklore_banner .content {
    max-width: 100%;
  } */

  .folk-volume-img {
    height: 250px;
  }

  .folklore_banner .lefttext {
    padding: 25px 0;
  }

  .folklore_banner .bannerimg_wrap {
    padding: 60px 0;
  }
}

@media (max-width: 768px) {

  /* banner section */
  .banner-section {
    width: 100%;
    height: auto;
    position: relative;
  }

  .banner-text {
    width: 65%;
  }

  .banner-title {
    font-size: 38px;
    padding-bottom: 13px
  }

  .banner-desc {
    font-size: 16px;
  }

  /* about section */
  .folk-about {
    width: 100%;
    height: auto;
  }

  .about-desc-box {
    width: 100%;
  }

  .folk-about-title {
    font-size: 30px;
  }

  .folk-about-mini-title {
    padding-top: 5px;
  }

  /* folk-vol section */
  .folk-volume-section {
    padding: 50px 0;
  }

  .folk-volume-main-box {
    gap: 3.5rem;
  }

  .folk-volume-title {
    font-size: 24px;
  }

  .inner-title {
    font-size: 30px;
    font-weight: 500;
  }

  .vol-desc {
    font-size: 13px;
  }

  /* contributor section */


  .contributor-title {
    font-size: 36px;
    width: 100%;
  }

  .contributor-desc {
    width: 78%;
    font-size: 15px;
  }

  .contributor-btn {
    padding: 7px 12px;
  }

  /* .folklore_banner .content {
    max-width: 100%;
  } */

  .totalcontentwrap {
    width: 100%;
    height: 300px;
  }

  .contributor-main {
    width: 100%;
  }

  .folk-volume-img {
    height: 190px;
  }

  .folklore_banner .lefttext {
    padding: 0px 0px 15px;
  }

  .folklore_banner .content {
    padding: 30px 0px;
  }

  .folklore_banner .text {
    text-align: justify;
  }
  .folklore_banner .bannerimg_wrap {
    padding: 0;
  }
}

@media (max-width: 576px) {

  /* banner section */
  .banner-section {
    width: 100%;
    height: auto;
    position: relative;
    background-size: cover;
  }

  .banner-main-box {
    margin-top: 1.7rem;
    padding: 0;
  }

  .banner-text {
    width: 70%;
    padding: 50px 0;
    position: relative;
  }

  .banner-title {
    font-size: 28px;
    padding-bottom: 12px;
  }

  .banner-desc {
    font-size: 14px;
  }

  /* about section */
  .folk-about {
    width: 100%;
    height: auto;
  }

  .folk-about-title {
    font-size: 20px;
    font-weight: 700;
  }

  .folk-about-mini-title {
    font-weight: 500;
  }

  .about-desc-box {
    text-align: center;
  }

  /* folk-vol section */
  .folk-volume-main-box {
    gap: 1rem;
  }

  .volume-main-section {
    max-width: 100%;
    padding: 25px 5px;
  }

  .folk-volume-title {
    font-size: 22px;
  }

  .inner-title {
    display: none;
  }

  .vol-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px 15px;
  }

  .vol-btn a {
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    background-color: rgb(168, 34, 34);
    color: #fff;
    padding: 5px 15px;
    width: 100%;
    max-width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: 0;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 4px;
  }

  .vol-btn a:hover {
    color: #fff;
    background-color: rgb(48, 63, 78);
    transition: 0.6s;
  }

  /* contributor section */

  .contributor-title {
    font-size: 40px;
    width: 100%;
    margin-bottom: 10px;
    line-height: normal;
  }

  .contributor-desc {
    width: 75%;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .contributor-btn {
    padding: 5px 10px;
  }

}


@media (max-width: 768px) {
  .vol-btn a {
    font-size: 12px;
    max-width: 140px;
    height: 40px;
  }
}

@media (max-width: 480px) {

  /* banner section */
  .banner-section {
    width: 100%;
    position: relative;
    height: 170px;
  }

  .banner-img-box {
    width: 100%;
    height: 100%;
  }

  .banner-image {
    width: 100%;
    height: 100%;
  }

  .banner-main-box {
    margin-top: 1.5rem;
  }

  .banner-text {
    width: 80%;
    padding: 30px 0;
  }

  .banner-title {
    font-size: 18px;
    font-weight: 550;
    padding-bottom: 5px;
  }

  .banner-desc {
    width: 70%;
    font-size: 11px;
    font-weight: 400;
  }

  /* about section */
  .folk-about {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 25px 15px;
  }

  .folk-about-title {
    font-size: 20px;
  }

  .folk-about-mini-title {
    padding-top: 0px;
    font-size: 13px;
    font-weight: 650;
    word-spacing: 3px;
  }

  .about-desc-box {
    width: 100%;
    text-align: start;
  }

  .about-desc-box p {
    width: 100%;
    font-size: 13px;
  }

  /* volume-section */
  .volume-main-section {
    padding: 30px 0;
    text-align: start;
  }

  .folk-volume-title {
    font-size: 20px;
  }

  .inner-title {
    display: none;
  }

  .volume-img {
    width: 100%;
  }

  .volume-img img {
    width: 100%;
  }

  .folk-volume-section {
    padding: 0;
    height: auto;
  }

  .folk-volume-section .nagacontainer {
    padding: 0 10px;
  }

  /* contributor section */

  .contributor-title {
    font-size: 27px;
    width: 100%;
    padding-bottom: 7px;
  }

  .contributor-desc {
    width: 100%;
    font-size: 12px;
    padding-bottom: 15px;
  }

  .contributor-btn {
    padding: 4px 10px;
    border-radius: 10px;
    font-size: 12px;
  }

  .folk-volume-img {
    height: 160px;
  }
}