* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* volume-section */
.volume-section {
  width: 100%;
  height: 233px;
  background-color: #f9f6f0;
  position: relative;
  font-family: "Montserrat";
}

.folklore-vol {
  font-size: 12px;
  word-spacing: 3px;
  padding-top: 15px;
}

.vol-span {
  font-size: 12px;
  font-weight: 600;
}

.vol-span i {
  font-size: 10px;
  padding-right: 3px;
}

.volume1-main {
  width: 100%;
  max-width: 700px;
  margin: 38px auto;
  text-align: center;
}

.volume-title {
  font-size: 42px;
  font-family: Prata;
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding-bottom: 20px;
}

.volume-desc {
  font-size: 14px;
  font-weight: 600;
}

/* acknowledgement-section */

.acknowledgement-section {
  padding: 50px 0;
  font-family: "Montserrat";
}

.ack-title {
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
  font-family: Prata;
  padding-bottom: 25px;
}

.ack-mini-title {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 20px;
}

.ack-desc-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  font-weight: 400;
}

/* tribe-section */

.tribe-section {
  width: 100%;
  padding: 50px 20px;
  background-color: #f9f6f0;
  font-family: "Montserrat";
}

.tribe-title {
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
  font-family: "Prata", serif;
  color: #39373A;
  margin-bottom: 5px;
}

.tribe-mini-title {
  font-size: 16px;
  line-height: 24px;
  color: #39373A;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin-bottom: 20px;
}

.tribe-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
}

.tribe-card {
  width: 31%;
  margin-bottom: 30px;
  /* padding: 13px 0; */
}

.card-title {
  font-size: 24px;
  line-height: normal;
  font-weight: 400;
  color: #39373A;
  font-family: "Prata", serif;
}

.card_img {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  background-color: rgb(148, 147, 147);
  position: relative;
}

.card_tagline {
  position: absolute;
  display: flex;
  top: 5%;
  right: 5%;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.card_img img {
  width: 100%;
  height: 100%;
}

.card-desc {
  font-size: 15px;
  padding: 20px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 95px;
}

.vol-btn {
  display: flex;
  /* justify-content: center; */
  justify-content: left;
  align-items: center;
  padding: 15px 0px 15px;
}

.vol-btn a {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(168, 34, 34);
  color: #fff;
  padding: 5px 15px;
  width: 100%;
  max-width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: 0;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 4px;
}

.vol-btn a:hover {
  color: #fff;
  background-color: rgb(48, 63, 78);
  transition: 0.6s;
}

/* responsive */

@media (max-width: 992px) {

  /* acknowledgement section */
  .tribe-title {
    font-size: 36px;
  }

  .tribe-mini-title {
    font-size: 12px;
  }

  .tribe-main {
    width: 100%;
  }

  .tribe-card {
    width: 48%;
  }

}

@media (max-width: 768px) {

  /* volume-section */
  .volume1-main {
    left: 27%;
  }

  /* ackowledgement-section */
  .acknowledgement-main {
    text-align: center;
  }

  .ack-desc-box {
    text-align: start;
    padding: 10px 0;
  }

  /* acknowledgement section */
  .tribe-title {
    font-size: 30px;
  }

  .tribe-mini-title {
    font-size: 12px;
  }

  .tribe-main {
    width: 100%;
  }

  .tribe-card {
    width: 45%;
  }

  .card-desc {
    font-size: 13px;
  }
  .vol-btn a {
    font-size: 12px;
    max-width: 140px;
    height: 40px;
  }
}

@media (max-width: 576px) {

  /* volume-section */
  .volume1-main {
    text-align: start;
    margin-left: 0;
    padding-top: 22px;
  }

  .volume-title {
    text-align: left;
    font-size: 28px;
  }

  .volume-desc {
    font-size: 14px;
  }

  .folklore-vol {
    display: none;
  }

  /* ackowledgement-section */
  .ack-title {
    font-size: 25px;
    font-weight: bold;
  }

  .ack-mini-title {
    font-size: 12px;
  }

  /* Tribe section */
  .tribe-section {
    text-align: center;
  }

  .tribe-title {
    font-size: 26px;
  }

  .tribe-mini-title {
    font-size: 11px;
  }

  .tribe-main {
    width: 100%;
  }

  .tribe-card {
    width: 47%;
    text-align: start;
  }

  .card-title {
    font-size: 18px;
  }

  .card-desc {
    width: 100%;
    font-size: 10px;
    text-align: start;
    line-height: normal;
    font-weight: 500;
    height: 70px;
  }
}

@media (max-width: 480px) {

  /* volume-section */

  .volume-title {
    font-size: 23px;
  }

  .volume-desc {
    font-size: 12px;
  }

  /* ackowledgement-section */
  .ack-title {
    font-size: 20px;
    font-weight: 600;
  }

  .ack-mini-title {
    font-size: 11px;
  }

  /* Tribe-section */
  .tribe-section {
    text-align: center;
  }

  .tribe-title {
    font-size: 20px;
  }

  .tribe-mini-title {
    font-size: 11px;
    text-align: left;
  }

  .tribe-main {
    width: 100%;
  }

  .tribe-card {
    width: 100%;
  }

  .card_img {
    width: 100%;
    height: 130px;
  }
}
