.anagami-card{
    width: 100%;
    padding: 0 10PX;
}
.card-main-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}
.card-img-div{
    width: 35%;
}
.card-img-div img{
    width: 100%;
}
.card_details_page{
    width: 100%;
}
.card-address i{
    color: gray;
}
.card-date{
    font-size: 13px;
    font-weight: 500;
}
.card-anagami-title{
    font-size: 15px;
    font-weight: 600;
    color: #A82222;
}
.card-description{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content:flex-start;
   gap: 5px;
}
.card-description span{
    font-size: 12px;
    padding-right: 10px;
}
.card-description span i{
    padding-right: 5px;
}
.card-anagami-desc{
    width: 100%;
    padding-top: 10px;
    font-size: 13px;
}





/* Responsive */
@media (max-width:992px){
    .anagami-card{
        width: 100%;
        padding: 0 5px;
    }
    .anagami-card{
        width: 100%;
    }
    .card-main-box{
        justify-content: start;
        gap: 10px;
    }
    .card-img-div{
        width: 32%;
    }
    .card_details_page{
        /* width:66%; */
        width: 100%;
    }
    .card-anagami-desc{
        width: 100%;
        font-size: 11px;
        font-weight: 450;
        line-height: 15px;
    }
    .card-anagami-title{
        font-size: 13px;
        font-weight: 800;
    }
    .card-date{
        font-size: 11.5px;
        font-weight: 450;
    }
}
@media (max-width:768px){
    .anagami-card{
        width: 100%;
        padding: 0 5px;
    }
    .anagami-card{
        width: 100%;
    }
    .card-main-box{
        justify-content: start;
        gap: 10px;
    }
    .card-img-div{
        width: 40%;
    }
    .card-img-div img{
        width: 100%;
    }
    .card_details_page{
        /* width:66%; */
        width: 100%;
    }
    .card-anagami-desc{
        width: 100%;
        font-size: 13px;
        font-weight: 450;
        line-height: 22px;
    }
    .card-anagami-title{
        font-size: 16px;
        font-weight: 800;
    }
    .card-date{
        font-size: 11.5px;
        font-weight: 450;
    }
   
}
@media (max-width:576px){
    .anagami-card{
        width: 100%;
        padding: 10px 6px;
    }
    .card-main-box{
        align-items: flex-start;
        justify-content: start;
        gap: 10px;
    }
    .card-img-div{
        width: 50%;
    }
    .card-img-div img{
        width: 100%;
    }
    .card_details_page{
        /* width:66%; */
        width: 100%;
    }
    .card-description{
        flex-wrap: wrap;
        gap: 5px;
    }
    .card-anagami-desc{
        width: 100%;
        font-size: 11px;
        font-weight: 450;
        line-height: 18px;
        padding-bottom: 20px;
    }
    .card-anagami-title{
        font-size: 14px;
        font-weight: 700;
    }
    .card-date{
        font-size: 11.5px;
        font-weight: 450;
    }
}
@media (max-width:320px){
    .card-description span{
        font-size: 10px;
    }
}


