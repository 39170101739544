* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Montserrat";
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

/* Angami-Section */
.angami-vol span i {
  font-size: 8px;
  padding: 0 5px;
}
.angami-main {
  padding-top: 35px;
  width: 27%;
  text-align: start;
}
.angami-main .volume-title {
  text-align: start;
  padding-bottom: 5px;
}

/* angami-main-section */
.angami-main-section {
  width: 100%;
  padding: 30px 0;
}
.main-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
}

/* angami-main-left */
.angami-main-left {
  cursor: pointer;
  width: 280px;
}
.left-title-box {
  width: 100%;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  transition: all 1s ease-in-out;
}
.left-title-box div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.left-title-box .title-headind {
   font-size: 16px;
   font-weight: 500;
}

.box-lists {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.list-number {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: lightgray;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-lists:hover {
  background-color:#FDE5D0;
}
.box-lists:hover p.list-number {
  background-color: black;
  color: #fff;
}
.last-li {
  border-bottom: none;
}

/* angami-main-right */
.angami-main-right {
  width: 940px;
}
.main-right-title {
  font-size: 24px;
  font-weight: 400;
  padding-left: 20px;
}
.main-right-box {
  padding-top: 25px;
}

/* angami-pagination */
.angami-pagination {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.angami-pagination span {
  font-size: 15px;
}
.angami-pagination span i {
  padding: 0 5px;
  font-size: 13px;
  cursor: pointer;
}
.pagination-list li {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
}
.active {
  background-color: #FDE5D0;
}
.pagination-text{
  display: inline;
}

@media (max-width: 992px) {
  /* angami-main-section */

  .angami-main-section {
    width: 100%;
  }
  .main-box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 8px;
    gap: 15px;
  }
  /* angami-main-left */
  .angami-main-left {
    width: 22%;
  }
  .left-title-box div i {
    display: none;
  }
  .title-headind {
    font-size: 12.5px;
    font-weight: 500;
  }
  .list-name {
    font-size: 12px;
  }
  /* angami-main-right */
  .angami-main-right {
    width: 75%;
  }
}
@media (max-width: 768px) {
  .angami-main-section {
    padding-top: 10px;
  }
  .main-box {
    flex-direction: column;
    padding: 10px 20px;
    gap: 30px;
  }
  /* angami-main-left */
  .angami-main-left {
    width: 100%;
    position: relative;
  }
  .title-headind {
    font-size: 20px;
    padding: 0 15px;
  }
  .list-name {
    font-size: 16px;
    padding-left: 15px;
  }
  .left-list-box {
    z-index: 2;
    position: absolute;
    left: 1px;
    background: white;
    box-shadow: 0px 0px 5px 0px #969494;
    width: 100%;
  }
  .left-title-box {
    justify-content: flex-end;
  }
  /* angami-main-right */
  .angami-main-right {
    width: 100%;
  }
  .main-right-title {
    text-align: start;
    font-size: 18px;
    font-weight: 500;
  }
}
@media (max-width: 576px) {
  .angami-main{
    width: 65%;
  }
  /* left-list-box */
  .box-lists:hover {
    background-color: bisque;
    transition: all 0.4s ease-in-out;
  }
  .angami-pagination{
    justify-content: space-around;
  }
  .left-title-box {
    justify-content: space-between;
  }
}
@media (max-width:480px){
  .pagination-text{
    display: none;
  }
  .angami-pagination{
    width: 100%;
    font-size: 10px;
    justify-content:space-between;
  }
  .pagination-list{
    gap: 7px;
  }
  .angami-pagination span{
    font-size: 10px;
  }

  .title-headind {
    font-size: 16px;
  }
 
}
