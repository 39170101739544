.kashopapu_section {
  width: 100%;
}

.kashopapu_banner {
  width: 100%;
  height: auto;
  background-color: #f9f6f0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px 0;
  position: relative;
}

.kashopapu_banner::before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000036;
  z-index: 1;
}

.kasho_banner_main {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.kasho_title_tag {
  color: #fff;
  font-size: 12px;
}

.active_tag {
  font-size: 12px;
  font-weight: 550;
}

.kasho_desc_tag {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.kashopapu_desc_box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.kasho_tagline {
  color: #fff;
  padding: 3px 10px;
  font-size: 11px;
  /* width: 60px; */
  text-align: center;
  background-color: #c9bfbf54;
  font-weight: 600;
  border-radius: 4px;
}

.kasho_main_title {
  color: #fff;
  padding-top: 1rem;
  font-weight: 550;
  font-size: 35px;
  letter-spacing: 1px;
  font-family: "Prata", serif;
  max-width: 630px;
  line-height: normal;
}

.kasho_short_text {
  padding-top: 10px;
  color: #fff;
}

.kasho_banner_main .card-description {
  color: #fff;
}

.kashopapu_box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  padding-top: 1rem;
}

.kashopapu-left_box {
  width: 25%;
  padding-top: 1rem;
}

.kasho_dropdown {
  width: 100%;
}

.kashopapu_contributor_first {
  width: 100%;
  padding-top: 4rem;
}

.kashopapu_right_box {
  width: 75%;
}

.kasho_content_para_box {
  width: 100%;
  padding-bottom: 5rem;
}

.contributor_main_box {
  width: 100%;
}

.kasho_contri_title {
  padding-bottom: 0.5rem;
  font-size: 22px;
  font-weight: 550;
  font-family: "Prata", serif;
}

.contributor_list {
  width: 100%;
  height: auto;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  margin-top: 10px;
}

.contributor_list tr {
  width: 100%;
}

.contributor_list tr td {
  padding: 4px 5px;
  text-align: left;
  max-width: 50%;
}

.kasho_contri_qus p {
  width: 115px;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap !important;
  margin: 0;
  padding: 0;
}

.kasho_contri-ans p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  line-height: normal;

}

.Contri_person_name {
  font-weight: 600 !important;
}

.kasho_right_para {
  padding-top: 0.8rem;
  font-family: "Montserrat";
}

.pagination_box {
  width: 100%;
  border-top: 1px solid beige;
  padding-top: 1rem;
}

.pagination_box_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-text {
  padding: 0 10px;
}

.disableColor {
  color: gray;
}

.next_box,
.prev_box {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #605f5f;
  opacity: 0.70;
}

.next_box.active,
.prev_box.active {
  opacity: 1;
  background-color: transparent;
}

.kasho_contri-second {
  padding-top: 2rem;
  display: none;
}

.kashpapu_tribe_section {
  background-color: #fff;
}

.tribe-main {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 2.5rem;
  justify-content: flex-start;
  gap: 1.7rem;
}

.kashopapu_card_main {
  width: 100%;
  height: auto;
  padding: 10px 20px;
}

.kasho_card_title {
  font-size: 18px;
  color: #393939;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}

.kasho_desc {
  width: 100%;
  font-size: 14px;
  padding: 5px 0;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 55px;
}

.showThreeLineFolkColl {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.Kasho_card_link {
  font-size: 14px;
  line-height: normal;
  text-align: left;
  font-weight: 500;
  color: #39373A;
  padding: 13px 0;
  text-decoration: underline;
}

/* responsive */
@media (max-width: 992px) {
  .tribe-main {
    gap: 1.5rem;
  }

}

@media (max-width: 768px) {
  .kashopapu_box {
    flex-direction: column;
  }

  .kashopapu-left_box {
    width: 100%;
  }

  .kashopapu_contributor_first {
    display: none;
  }

  .kashopapu_right_box {
    width: 100%;
  }

  .kasho_contri-second {
    display: block;
  }

  .kashopapu_card_description {
    width: 100%;
  }

  .kashopapu_card_description span {
    font-size: 10.5px;
    font-weight: 460;
  }

  .tribe-main {
    gap: 1rem;
  }

  .kasho_banner_main {
    height: 330px;
  }
}

@media (max-width: 586px) {

  .kasho_desc_tag {
    display: none;
  }

  .kashopapu_title_box span {
    display: none;
  }

  .page-text {
    display: none;
  }

  .pagi_arrow {
    font-size: 35px;
    font-weight: bold;
  }

  .kashopapu_card_main {
    width: 100%;
    width: auto;
    padding: 5px 10px;
  }

  .kashopapu_card_description span {
    font-size: 14px;
    font-weight: 400;
    padding-top: 5px;
  }

  .kasho_desc {
    padding: 8px 0;
    width: 100%;
    text-align: start;
  }

  .kashpapu_title {
    font-size: 27px;
    font-weight: 550;
    text-align: start;
  }

  .kasho_main_title {
    font-size: 24px;
    line-height: normal;
    padding-top: 0;
  }

  .kasho_banner_main {
    height: 230px;
  }
}

@media (max-width: 480px) {

  /* .card-description {
    width: 100%;
  }
  .card-address,
  .card-time {
    font-size: 10px;
  }
  .kasho_right_para {
    font-size: 13px;
  } */
  .kashopapu_card_description span {
    font-size: 11px;
    font-weight: 450;
  }

  .kasho_desc {
    font-size: 11px;
  }

  .kashpapu_title {
    font-size: 19px;
    font-weight: 500;
  }

  .kasho_card_title {
    font-size: 14px;
    text-align: left;
    height: 35px;
  }

  .Kasho_card_link {
    font-size: 12px;
  }

  .kasho_main_title {
    font-size: 16px;
  }

  .kasho_short_text {
    padding-top: 5px;
    font-size: 10px;
  }

  .card-description span {
    font-size: 10px;
  }
}